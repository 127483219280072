import React from "react";
import { Row } from "react-bootstrap";
import OnboardingStepperProps from "../../interfaces/components/OnboardingStepper/OnboardingStepperProps";

const OnboardingStepperMobile: React.FC<OnboardingStepperProps> = ({
  steps,
  currentStep,
}) => {
  return (
    <Row
      style={{
        paddingLeft: "50px",
        paddingRight: "50px",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* Vertical Line */}
      <div
        style={{
          position: "absolute",
          left: "50px", // Position of the line to the left of the content
          top: 0,
          bottom: 0,
          width: "0.15px", // Keep the width as 1px
          backgroundColor: "#101324", // New color for the line
          transform: "scaleX(0.15)", // Scale the line to make it appear thinner
          transformOrigin: "left", // Keep the scale from the left side
          opacity:"0.8"
        }}
      />

      {steps.map((step, index) => (
        <Row
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "12px",
            marginBottom: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              position: "relative",
            }}
          >
            {/* Step Number */}
            <span
              style={{
                color: "#526ABF",
                fontSize: "38px",
                fontFamily: '"Helvetica-Neue", sans-serif',
                fontWeight: index === currentStep ? "bold" : "normal",
                marginRight: "20px", // Space between number and caption
              }}
            >
              {index < currentStep ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38px"
                  height="38px"
                  fill="currentColor"
                  className="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              ) : (
                (index + 1).toString().padStart(2, "0") // Display '01', '02', '03', etc.
              )}
            </span>

            {/* Step Caption */}
            <span
              style={{
                color: "#272f53",
                fontSize: "30px",
                fontWeight: index === currentStep ? "bold" : "normal",
              }}
            >
              {step}
            </span>
          </div>
        </Row>
      ))}
    </Row>
  );
};

export default OnboardingStepperMobile;
