import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

import Footer from "../components/Footer/Footer";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import ContactUs from "../components/ContactUs/ContactUs";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import FooterMobile from "../components/Footer/FooterMobile";

function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Private Policy";
  }, []);

  return (
    <Container fluid>
      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{ backgroundColor: "#101324" }}
      >
        <HeaderMenu />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
        style={{
          backgroundColor: "#101324",
        }}
      >
        <HeaderMenuMobile />
      </Row>
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: "80px",
        }}
      >
        <Col>
          <p>Updated Date: 18/05/2023</p>
        </Col>
      </Row>
      {/* Privacy Policy Content */}
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: "40px",
        }}
      >
        <Col>
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
            }}
          >
            Privacy Statment
          </h2>

          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            At Lagonem Investments Ltd (we, us, our) the protection of your
            personal data is a top priority. Keeping your data secure and
            private is part of our philosophy for delivering high standards of
            services. These values are the cornerstone of our corporate culture
            and for this purpose it is our commitment to process your personal
            data as follows:
          </p>

          <ul style={{ marginBottom: "40px" }}>
            <li>Fairly and lawfully;</li>
            <li>In an appropriate manner;</li>
            <li>For limited purposes and not longer than necessary;</li>
            <li>For the purpose required and not in an excessive way;</li>
            <li>Keep them up-to-date and accurate;</li>
            <li>
              Processed in line with your individual rights and in accordance
              with applicable Law;
            </li>
            <li>
              In a secure way avoiding unauthorized or unlawful processing;
            </li>
            <li>
              Protected against breach, accidental loss, destruction, or damage
              by using appropriate technical and organizational measures;
            </li>
            <li>
              Not transferred to third parties or organizations without adequate
              protection.
            </li>
          </ul>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            This privacy notice describes how we process your personal data
            during and after the provision of our services, in accordance with
            the EU Regulation 2016/679 (GDPR), Cyprus’ Law providing for the
            Protection of Natural Persons with regard to the Processing of
            Personal Data and for the Free Movement of such Data of 2018 (Law
            125(I)/2018), and it applies to personal data provided to us, both
            by yourself or by third parties.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Lagonem Investments Ltd as an authorised Alternative Investment Fund
            Manager (AIFM) offers a wide spectrum of core and ancillary fund
            management services through a number of affiliate companies, and any
            reference to our company’s services in this privacy notice includes
            the operations of our affiliate companies.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            We will work closely with you and third parties, where appropriate,
            to ensure that all statutory requirements and our policies dealing
            with personal data protection, strike an effective balance between
            your personal interests and the legitimate interests of our company
            as an AIFM.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            During the course of providing our services, we will collect, use,
            store, distribute and generally process your personal data. All data
            users within our company are obliged to comply with the provisions
            of this privacy notice and our internal data management and
            protection policies when processing your personal data.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Identity and contact details of the Data Controller and Data
            Protection Officer
          </h2>
          <h4
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            {"a) Data Controller"}
          </h4>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Lagonem Investments Ltd is a Cyprus private limited liability
            company with registration number HE 436270 and is the «Data
            Controller» pursuant to the GDPR, and related Cyprus Laws, and
            determines how your personal data is kept and processed.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            The main establishment and the central administration of the Data
            Controller is situated at 31 Gladstonos Street, 1095, Nicosia,
            Republic of Cyprus.
          </p>
          <h4
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            {"b) Data Protection Officer (DPO)"}
          </h4>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            We have designated a Data Protection Officer (DPO), who is
            responsible to monitor compliance with this notice as well as the
            applicable Laws and liaise with the Cyprus Supervisory Authority,
            namely the Office of the Commissioner for Personal Data Protection.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            The DPO may be contacted directly with regards to all matters
            concerning this notice and the processing of your personal data
            including the enforcement of all applicable and available rights.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            Official requests may be made by post at 31 Gladstonos Street, 1095,
            Nicosia, Republic of Cyprus, or electronically at{" "}
            <b>
              <i>info@ependisis.com</i>
            </b>
            .
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            The kind of information we hold about you
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Lagonem Investments Ltd, collects and processes the minimum and a
            specific set of personal data allowing the company to comply with
            its contractual and statutory obligations.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            To the same end we may obtain personal data concerning yourself from
            publicly available and accessible sources, as well as from other
            sources such as data intelligence databases and internet search
            engines and other third parties. Should this be the case, you will
            be informed accordingly.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            In addition to the above, the categories of personal data which may
            be processed include the following:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>Personal Information;</li>
            <li>Identification documents / bank account details;</li>
            <li>Contact details;</li>
            <li>Residential / correspondence address;</li>
            <li>Education / Employment information;</li>
            <li>Business / Investment activities;</li>
            <li>
              Financial information, including account and transactional
              information and history;
            </li>
            <li>
              Financial circumstances, including personal wealth, assets and
              liabilities;
            </li>
            <li>
              Proof of income and expenditure, credit and borrowing history and
              needs and goals;
            </li>
            <li>Income, taxation and other related financial details;</li>
            <li>CV;</li>
            <li>Personal / Family information;</li>
            <li>
              Ultimate Beneficial Owner / Board of Directors information (where
              applicable);
            </li>
          </ul>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Should there be a need to further process the personal data for a
            purpose other than that for which they were initially collected, you
            will be informed about the additional purpose and the relevant
            details in respect to the further processing.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            When required by law or with your express consent we may collect
            special categories of personal data. Pursuant to the definition
            given by the GDPR, these data may include racial or ethnic origin,
            political opinions, religious or philosophical beliefs, trade union
            membership, the processing of genetic data, biometric data, data
            concerning health, sex life or sexual orientation and criminal
            records.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            How we will use your personal information
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            To present our Website and its contents to you.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            To facilitate the listing of your buyer, seller, or advisor profile
            on the Website.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            To provide you with information, products, or services that you
            request from us, including without limitation, (for startup users)
            displaying authorized business information of startups to certain
            potential buyers.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            To fulfill any other purpose for which you provide it.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            To provide you with notices about your account, including expiration
            and renewal notices.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            To notify you about changes to our Website or any products or
            services we offer or provide through it.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            To allow you to participate in interactive features on our Website.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            In any other way we describe when you provide the information.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            For any other purpose with your consent.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            With your consent, we will use your information to contact you about
            our own and third-parties&#39; goods and services that may be of
            interest to you. If you do not want us to use your information in
            this way, please adjust your user preferences in your account
            profile. For more information, see “Your Information Rights and
            Controls” below.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Recipients and Users of personal data
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            We will only use your personal data for the purposes mentioned
            above, for performing our fund management commercial agreement and
            for legitimate purposes. Your data may be processed through our
            secure computer network systems and accessed only by authorised
            users and employees within our company.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Third Party Processors / Joint Controllers
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            Data processing may be carried out on behalf of us by third party
            data processors, or by joint controllers pursuant to written and
            express authorisation for specific purposes contained in the
            relevant authorisation. We have taken all necessary steps, including
            the implementation of appropriate legal, technical and
            organisational measures, to ensure that the data processing meets
            all applicable statutory requirements, thus safeguarding your
            rights.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Transfer of personal data to third countries or international
            organisations
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Our company may operate within the European Union (EU), the European
            Economic Area (EEA) and other third countries, and therefore your
            personal data or part of them may have to be transferred overseas.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            We have taken all reasonable steps to ensure that personal data is
            provided with adequate protection based on international protection
            frameworks and that all transfers of data are conducted pursuant to
            our written agreements and the supervisory authority’s guidelines
            (if required) and/or other legal and/or regulatory requirements.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Data Retention policy
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            We will only retain your personal data for as long as it is required
            to fulfil our contractual obligations and any legal, accounting,
            tax, reporting and/or other statutory obligations.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Where no such contractual or statutory obligation applies, it is our
            policy to keep the personal data for 6 (six) years after the
            provision or termination of the requested services and/or the
            transactions (where applicable).
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            The personal data processed for marketing and/or other commercial
            purposes shall be kept with us until you notify us that you no
            longer wish for it to be used for such purpose.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Security
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Here at Lagonem Investments Ltd, security of your personal data is
            taken very seriously. For both hard and electronic copy processing
            we have data management systems which are periodically updated in
            accordance with technological development and a framework of
            multilevel security policies to hold data confidential and secure.
            Security measures have also been taken to prevent your personal
            information from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Moreover, we have procedures to deal with suspected data security
            breaches or threats and should a breach ever materialise, you will
            be notified accordingly, along with the supervisory authority, if we
            are required to do so.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            More information on our security framework is available upon
            request.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Children’s data
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Our services are not intended for children, and we do not knowingly
            collect personal data relating to children.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            For the purposes of this privacy notice, «children» are individuals
            who are under the age of 18 (eighteen) years old.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Your rights in connection with personal data
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            You enjoy several rights relating to the processing of your data.
            Any personal data related requests shall be processed within
            reasonable time and in any case within 1 (one) month from the signed
            written request. This period of time may be extended under certain
            circumstances by further 2 (two) months.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            You will not have to bare any cost to exercise any of your rights.
            We may though charge a reasonable fee should your requests be
            clearly unfounded or excessive, due to their repetitive character,
            or refuse to comply with such request.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            We may request specific information to assist us confirm your
            identity and ensure your capacity to enforce your rights. This is
            part of our security measures to certify that personal information
            is not disclosed to any person who has no right to receive it.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            Save for any statutory provisions to the contrary, the rights
            available to you by law are the following:
          </p>
          <h4
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            {"a) Right of Access"}
          </h4>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            You have the right to enquire and obtain information from us, as to
            whether or not your personal data is being processed, including
            information on the purposes and legal standing of the processing,
            the categories of data, the recipients or group of recipients and
            where possible, the envisaged period for which the personal data
            will be stored.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            Where applicable, you may also enquire in respect of any transfer of
            personal data to a third country or international organisation as
            well as to obtain more information about our existing security
            measures / safeguards governing such transfer.
          </p>
          <h4
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            {"b) Rectification / Amendment"}
          </h4>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            We aim in having up-to-date personal data kept in our records. Any
            inaccurate or incomplete personal data may be updated or rectified
            pursuant to a formal request.
          </p>
          <h4
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            {"c) Right of erasure (right to be forgotten)"}
          </h4>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Save for any limitations provided by express legal or regulatory
            provisions, including our policies for data retention, you have the
            right to request your personal data to be erased from our database,
            should any of the following occur:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>
              The personal data is no longer necessary in relation to the
              purposes for which it was initially collected;
            </li>
            <li>There is an objection to the further processing;</li>
            <li>The personal data has been unlawfully processed;</li>
            <li>
              The personal data should be erased in compliance with a legal
              obligation;
            </li>
          </ul>
          <h4
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            {"d) Restriction of data processing"}
          </h4>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Provided that no statutory exceptions apply, should any of the
            following apply, you have the right to request from us to restrict
            the further processing of your personal data:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>There is a dispute as to the accuracy of the personal data;</li>
            <li>
              The processing is unlawful, and the erasure right was not
              requested;
            </li>
            <li>
              The personal data will be used to establish, exercise or defend
              legal claims;
            </li>
          </ul>
          <h4
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            {"e) Right to object / withdraw consent"}
          </h4>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Where the personal data processing is based exclusively on consent,
            you have the right to request from us to withdraw such consent at
            any given time and object to the further processing. Such withdrawal
            will not affect the lawfulness of any data processing based on that
            ground prior to your withdrawal.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Upon receiving you request, we will no longer process your
            information for the purposes you originally agreed to, unless
            another legal basis exists, or for the establishment, exercise or
            defence of legal claims.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Should there be a distinct processing operation based on consent, we
            will take all necessary steps to ask for your separate consent.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            The right of objection may also be exercised against the receipt of
            commercial / marketing information and materials.
          </p>
          <h4
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            {"f) Right of portability"}
          </h4>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            In case the processing is based on consent or for the performance of
            a contract, where personal data is processed by automated means, you
            have the right to receive the personal data in a structured,
            commonly used and in a machine- readable form. Where it is
            technically feasible, you have the right to request the personal
            data to be transmitted directly from one data controller to another;
            thus, from one organisation to another.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            Subject to any statutory requirement, the right of portability does
            not extend to personal data produced by us, such as activity
            registry or other results of algorithmic analysis.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Changes to the Privacy Notice
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            We reserve the right to amend or update this privacy notice at any
            time, and you will be informed when this shall take place. We may
            also notify you in other ways from time to time about the processing
            of your personal data.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Complaints
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Complaints relating to the processing of any personal data may be
            communicated directly to the DPO, either by post at 31 Gladstonos
            Street, 1095, Nicosia, Republic of Cyprus or electronically at 
            <b>
              <i> info@ependisis.com</i>
            </b>
            .
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            Complaints may also be lodged before the Office of the Commissioner
            for Personal Data Protection, by post at 1 Iasonos Str. 1082,
            Nicosia, Republic of Cyprus.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <ContactUs />
      </Row>
      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{ marginLeft: "1%", marginRight: "1%", marginTop: "1%" }}
      >
        <Footer />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
        style={{ marginLeft: "2%", marginRight: "2%", marginTop: "4%" }}
      >
        <FooterMobile />
      </Row>
    </Container>
  );
}

export default PrivacyPolicy;
