import { Row, Navbar, Nav, Col, NavDropdown, Container } from "react-bootstrap";
import "./HeaderMenu.css";
import logo from "../../assets/ependisis-logo-no-outline.png";
import { useEffect, useState } from "react";
import { UserInfo } from "../../redux/UserInfoReducer";
import { getUserInfo, useLogout } from "../../auth/AuthUtils";
import { Link } from "react-router-dom";
import { PersonCircle } from "react-bootstrap-icons";

interface HeaderMenuProps {
  showUserIcon?: boolean; // Add a prop to control the visibility of the user icon
}

function HeaderMenu({ showUserIcon = true }: HeaderMenuProps) {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const handleLogout = useLogout();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = getUserInfo();
      if (user) {
        setUserInfo(user);
      }
    };
    fetchUserInfo();
  }, []);

  const handleAdminPage = () => {
    console.log("Accessing Admin Page");
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <Col></Col>
      <Col xs={8}>
        <Row className="justify-content-center" style={{ zIndex: "2", position: "relative" }}>
          <div className="logo">
            <a href="/">
              <img
                src={logo}
                alt="Logo"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </a>
          </div>
        </Row>
        <Row className="justify-content-center" style={{ paddingBottom: "20px" }}>
          <Navbar bg="transparent" expand="lg" style={{ paddingTop: "0", paddingBottom: "0" }}>
            <Container fluid>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto">
                  <Nav.Link href="/buyers" className="header-option">BUYERS</Nav.Link>
                  <Nav.Link href="/aboutUs" className="header-option">ABOUT</Nav.Link>
                  <Nav.Link href="/sellers" className="header-option">SELLERS</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Row>
      </Col>
      <Col className="last-column">
        {showUserIcon && (
          <Nav className="ai-center">
            <NavDropdown
              title={<PersonCircle size={30} style={{ cursor: "pointer" }} />}
              id="basic-nav-dropdown"
              onSelect={(eventKey: any) => {
                if (!userInfo && eventKey === "login") {
                  window.location.href = "/login";
                }
              }}
            >
              {userInfo === null && <NavDropdown.Item eventKey="login">Log in</NavDropdown.Item>}
              {userInfo !== null && (
                <>
                  <NavDropdown.Item disabled>Hello, {userInfo.name}</NavDropdown.Item>
                  <NavDropdown.Divider />
                  {userInfo?.roles === "Admin" && (
                    <NavDropdown.Item as={Link} to="/admin/dashboard/" onClick={handleAdminPage}>
                      Admin Page
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                </>
              )}
            </NavDropdown>
          </Nav>
        )}
      </Col>
    </div>
  );
}

export default HeaderMenu;
