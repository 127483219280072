import React from "react";
import PlanCardPropsNew from "../interfaces/components/PlanCard/PlanCardPropsNew";
import "./PlanCardContainer.css";
import { Col } from "react-bootstrap";
import { updatePackage, updateRole } from "../redux/RolePackageReducer";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import PlanCardNew from "../components/PlanCardNew/PlanCardNew";

function PlanCardNewContainer({
  data,
  roleSelected,
}: {
  data: PlanCardPropsNew[];
  roleSelected: string;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleButtonClick = (role: string | null, userPackage: string) => {
    secureLocalStorage.setItem("role", role ?? "");
    secureLocalStorage.setItem("package", userPackage);
    dispatch(updateRole(role));
    dispatch(updatePackage(userPackage));
    navigate("/signup");
  };

  return (
    <div className="plan-card-container-new">
      {data.map((item, index) => (
        <Col
          xs={12} /* Full width on extra small devices (mobile) */
          sm={6} /* 50% width on small devices */
          md={4} /* 33.33% width on medium devices */
          lg={3} /* 25% width on large devices (desktop) */
          key={index}
          className="custom-width-desktop mobile-margin-bottom" 
        >
          <PlanCardNew
            key={index}
            {...item}
            buttonMethod={() =>
              handleButtonClick(roleSelected, (index + 1).toString())
            }
          />
        </Col>
      ))}
    </div>
  );
}

export default PlanCardNewContainer;
