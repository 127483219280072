import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Alert, Col, Form, Row, Toast, Spinner } from "react-bootstrap";
import "./OnboardingForm.css";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  GetAllCategory,
  SaveCustomerQuestions,
  UpdateCustomer,
} from "../../apis/EpendisisApi";
import { UpdateCustomerRequest } from "../../interfaces/models/UpdateCustomerRequest";
import { Update } from "@mui/icons-material";
import { questionnaire } from "../../static/Questionnaire";
import { AnsweredQuestionnaire } from "../../interfaces/pages/Questionnaire";
import { NumberLiteralType } from "typescript";
import { QuestionnaireUtility } from "../../static/utility/QuestionnaireUtility";
import { SaveCustomerQuestionRequest } from "../../interfaces/models/SaveCustomerQuestionRequest";
import { UserStatus } from "../../redux/UserInfoReducer";
interface FormData {
  id: number;
  address: string;
  postCode: string;
  city: string;
  country: string;
  company: string;
  position: string;
  phoneNumber: string;

  clientType: number | null;
  sectorsOfInterest: string[];
  assetStrategy: number[];
  idealTransactionMin: number | null;
  idealTransactionMax: number | null;
  idealInvestmentMin: number | null;
  idealInvestmentMax: number | null;
  idealTTMMin: number | null;
  idealTTMMax: number | null;
  idealProfitMin: number | null;
  idealProfitMax: number | null;
  countriesOfInterest: string[];
  netWorthMin: number | null;
  netWorthMax: number | null;
  existingSectors: string[];

  registartionNumber: string;
  businessDescription: string;
  businessWebsite: string;
  revenueLastYear: number | null;
  profitLastYear: number | null;
  totalDebt: number | null;
  numberOfEmployees: number | null;
  askingPrice: number | null;
  specificReqs: string;
}

// type ActiveButtons = {
//   highNetWorth: boolean;
//   institutional: boolean;
//   investmentFund: boolean;
//   brokerage: boolean;
// };

// const initialActiveButtons: ActiveButtons = {
//   highNetWorth: false,
//   institutional: false,
//   investmentFund: false,
//   brokerage: false,
// };

interface OnboardingFormProps {
  id: number;
  type: string;
  token: string;
  handleStatusUpdate: (newStatus: UserStatus) => void;
  handleRole: (role: string) => void;
}

const OnboardingForm: React.FC<OnboardingFormProps> = ({
  id,
  type,
  token,
  handleStatusUpdate,
  handleRole,
}) => {
  const [formData, setFormData] = useState<FormData>({
    id: id,
    address: "",
    postCode: "",
    city: "",
    country: "",
    company: "",
    position: "",
    phoneNumber: "",

    clientType: null,
    sectorsOfInterest: [],
    assetStrategy: [],
    idealTransactionMin: null,
    idealTransactionMax: null,
    idealInvestmentMin: null,
    idealInvestmentMax: null,
    idealTTMMin: null,
    idealTTMMax: null,
    idealProfitMin: null,
    idealProfitMax: null,
    countriesOfInterest: [],
    netWorthMin: null,
    netWorthMax: null,
    existingSectors: [],

    registartionNumber: "",
    businessDescription: "",
    businessWebsite: "",
    revenueLastYear: null,
    profitLastYear: null,
    totalDebt: null,
    numberOfEmployees: null,
    askingPrice: null,
    specificReqs: "",
  });

  const handleClientTypeChange = (event: SelectChangeEvent<number>) => {
    const selectedClientType = event.target.value as number;
    setFormData((prevFormData) => ({
      ...prevFormData,
      clientType: selectedClientType,
    }));
  };

  const handleCountries = (event: SelectChangeEvent<string[]>) => {
    const selectedCountries = event.target.value as string[];
    setFormData((prevFormData) => ({
      ...prevFormData,
      countriesOfInterest: selectedCountries,
    }));
  };

  function isValidString(input: string | null | undefined): boolean {
    if (input === null || input === undefined || input.trim() === "") {
      return false;
    }
    return true;
  }

  const [currentStep, setCurrentStep] = useState(1);
  // const [sector, setSector] = useState<string>(sectorsOfInterest[0] ?? "");
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [answeredQuestionnaire, setAnsweredQuestionnaire] = useState<
    AnsweredQuestionnaire[]
  >([]);
  const [loading, setLoading] = useState(true);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleSubmitOne = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (type === "Buyer") {
      if (
        formData.clientType === null ||
        formData.sectorsOfInterest.length === 0
      ) {
        setErrorMessage("Please select at least one answer in all sections.");
        setShowToast(true);
      } else {
        setShowToast(false);
        nextStep();
      }
    }
    if (type === "Seller") {
      setShowToast(false);
      nextStep();
    }
  };

  const handleUpdateCustomer = async (request: UpdateCustomerRequest) => {
    try {
      const response = await UpdateCustomer(request);
      return true;
    } catch (error) {
      return false;
    }
  };

  const populateQuestionnaireBuyer = (
    answeredQuestionnaire: AnsweredQuestionnaire[]
  ): AnsweredQuestionnaire[] => {
    for (const answeredQuestion of answeredQuestionnaire) {
      switch (answeredQuestion.id) {
        case 1:
          answeredQuestion.answer = [formData.clientType?.toString() ?? ""];
          break;

        case 2:
          answeredQuestion.answer = formData.sectorsOfInterest;
          break;

        case 3:
          answeredQuestion.answer = formData.assetStrategy.map((num: number) =>
            num.toString()
          );
          break;

        case 4:
          answeredQuestion.answer = [
            formData.idealTransactionMin?.toString() ?? "",
            formData.idealTransactionMax?.toString() ?? "",
          ];
          break;

        case 5:
          answeredQuestion.answer = [
            formData.idealInvestmentMin?.toString() ?? "",
            formData.idealInvestmentMax?.toString() ?? "",
          ];
          break;

        case 6:
          answeredQuestion.answer = [
            formData.idealTTMMin?.toString() ?? "",
            formData.idealTTMMax?.toString() ?? "",
          ];
          break;

        case 7:
          answeredQuestion.answer = [
            formData.idealProfitMin?.toString() ?? "",
            formData.idealProfitMax?.toString() ?? "",
          ];
          break;

        case 8:
          answeredQuestion.answer = formData.countriesOfInterest;
          break;

        case 9:
          answeredQuestion.answer = [
            formData.netWorthMin?.toString() ?? "",
            formData.netWorthMax?.toString() ?? "",
          ];
          break;

        case 10:
          answeredQuestion.answer = formData.existingSectors;
          break;

        case 11:
          if (isValidString(formData.registartionNumber)) {
            answeredQuestion.answer = [formData.registartionNumber];
          }
          break;

        case 12:
          if (isValidString(formData.businessDescription)) {
            answeredQuestion.answer = [formData.businessDescription];
          }
          break;

        case 13:
          if (isValidString(formData.businessWebsite)) {
            answeredQuestion.answer = [formData.businessWebsite];
          }
          break;

        case 14:
          answeredQuestion.answer = [
            formData.revenueLastYear?.toString() ?? "",
          ];
          break;

        case 15:
          answeredQuestion.answer = [formData.profitLastYear?.toString() ?? ""];
          break;

        case 16:
          answeredQuestion.answer = [formData.totalDebt?.toString() ?? ""];
          break;

        case 17:
          answeredQuestion.answer = [
            formData.numberOfEmployees?.toString() ?? "",
          ];
          break;

        case 18:
          answeredQuestion.answer = [formData.askingPrice?.toString() ?? ""];
          break;

        case 19:
          if (isValidString(formData.specificReqs)) {
            answeredQuestion.answer = [formData.specificReqs];
          }
          break;
      }
    }
    return answeredQuestionnaire;
  };

  const convertToQuestionAnswer = (
    answeredQuestionnaire: AnsweredQuestionnaire[]
  ): SaveCustomerQuestionRequest[] => {
    let newList: SaveCustomerQuestionRequest[] = [];
    for (const answeredQuestion of answeredQuestionnaire) {
      for (const answer of answeredQuestion.answer) {
        const newItem: SaveCustomerQuestionRequest = {
          questionId: answeredQuestion.id,
          question: answeredQuestion.questionnaire.question,
          answer: answer ?? "",
        };
        newList = [...newList, newItem];
      }
    }
    return newList;
  };

  const handleQuestionsApi = async (
    questions: AnsweredQuestionnaire[],
    userId: string
  ) => {
    const answered = populateQuestionnaireBuyer(questions);
    const convertedQuestionAnswers: SaveCustomerQuestionRequest[] =
      convertToQuestionAnswer(answered);

    if (convertToQuestionAnswer.length > 0) {
      try {
        const response = await SaveCustomerQuestions(
          convertedQuestionAnswers,
          userId
        );
        return true;
      } catch (error) {
        return false;
      }
    }
  };

  const handleSubmitTwo = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      existingSectors,
      assetStrategy,
      idealTransactionMin,
      idealTransactionMax,
      idealInvestmentMin,
      idealInvestmentMax,
      idealTTMMin,
      idealTTMMax,
      idealProfitMin,
      idealProfitMax,
      netWorthMin,
      netWorthMax,
    } = formData;

    if (type === "Buyer") {
      if (existingSectors.length === 0 || assetStrategy.length === 0) {
        setErrorMessage("Please select at least one answer in all sections.");
        setShowToast(true);
        return false;
      }

      if (
        (idealTransactionMin !== null &&
          idealTransactionMax !== null &&
          idealTransactionMin > idealTransactionMax) ||
        (idealInvestmentMin !== null &&
          idealInvestmentMax !== null &&
          idealInvestmentMin > idealInvestmentMax) ||
        (idealTTMMin !== null &&
          idealTTMMax !== null &&
          idealTTMMin > idealTTMMax) ||
        (idealProfitMin !== null &&
          idealProfitMax !== null &&
          idealProfitMin > idealProfitMax) ||
        (netWorthMin !== null &&
          netWorthMax !== null &&
          netWorthMin > netWorthMax)
      ) {
        setErrorMessage(
          "Please ensure all minimum values are less or equal than maximum values."
        );
        setShowToast(true);
        return false;
      }
      setErrorMessage("");
      setShowToast(false);
    }

    var updateCustomerRequest = new UpdateCustomerRequest({
      id: formData.id,
      address: formData.address,
      city: formData.city,
      postcode: formData.postCode,
      country: formData.country,
      phone: formData.phoneNumber,
      company: formData.company,
      position: formData.position,
    });
    try {
      await handleUpdateCustomer(updateCustomerRequest);
      await handleQuestionsApi(answeredQuestionnaire, formData.id.toString());
      handleStatusUpdate(UserStatus.PendingDocuments);
      handleRole(UserStatus.PendingDocuments);
    } catch {}
    console.log("Form submitted", formData);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    const newValue =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleStringClick = (value: string, field: keyof FormData) => {
    setFormData((prevData) => {
      const array = prevData[field] as string[];
      const newValue = array.includes(value)
        ? array.filter((item) => item !== value)
        : [...array, value];

      return { ...prevData, [field]: newValue };
    });
  };

  const handleNumberClick = (value: number, field: keyof FormData) => {
    setFormData((prevData) => {
      const array = prevData[field] as number[];
      const newValue = array.includes(value)
        ? array.filter((item) => item !== value)
        : [...array, value];

      return { ...prevData, [field]: newValue };
    });
  };

  const prevStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    setShowToast(false);
    setErrorMessage("");
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const inputStyles = {
    width: "100%",
    marginBottom: "20px",
    textAlign: "center",
    paddingTop: "15px",
    paddingBottom: "15px",
  };

  const renderForm = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            {loading ? (
              <Row
                className="justify-content-center"
                style={{
                  marginTop: "50px",
                  marginBottom: "50px",
                  flex: "1",
                }}
              >
                <Spinner animation="border" variant="primary" />
              </Row>
            ) : (
              <>
                <Form onSubmit={handleSubmitOne} style={{ display: "flex" }}>
                  <Col>
                    <Row
                      className="text-center"
                      style={{ paddingTop: "2%", marginBottom: "50px" }}
                    >
                      <h2
                        className="onboardingform-heading"
                        style={{ color: "#c3d0fd" }}
                      >
                        Let's start with a quick overview
                      </h2>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        sm={6}
                        m={6}
                        l={6}
                        className="contact-us-col"
                        style={{
                          paddingLeft: "10%",
                          paddingRight: "10%",
                          paddingBottom: "100px",
                        }}
                      >
                        <Row style={{ marginBottom: "25px" }}>
                          <p
                            className="onboardingform-label"
                            style={{
                              color: "white",
                              fontFamily: '"Helvetica-Neue-Light", sans-serif',
                              opacity: "0.9",
                              paddingLeft: "0",
                            }}
                          >
                            Please complete the following:
                          </p>
                        </Row>
                        <Row>
                          <FormControl
                            style={{
                              width: "100%",
                              marginBottom: "20px",
                            }}
                          >
                            <TextField
                              sx={{
                                input: { color: "white" },
                              }}
                              id="outlined-search"
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                              placeholder="Full Address"
                              required
                              variant="filled"
                              InputProps={{
                                classes: {
                                  underline:
                                    "MuiInput-underline-white MuiInput-onboardingform",
                                },
                                inputProps: {
                                  style: {
                                    textAlign: "center",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                  },
                                },
                              }}
                            />
                          </FormControl>
                        </Row>
                        <Row>
                          <FormControl
                            style={{
                              width: "100%",
                              marginBottom: "20px",
                            }}
                          >
                            <TextField
                              sx={{
                                input: { color: "white" },
                              }}
                              id="outlined-search"
                              name="postCode"
                              required
                              value={formData.postCode}
                              onChange={handleChange}
                              placeholder="Post Code"
                              variant="filled"
                              InputProps={{
                                classes: {
                                  underline:
                                    "MuiInput-underline-white MuiInput-onboardingform",
                                },
                                inputProps: {
                                  style: {
                                    textAlign: "center",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                  },
                                },
                              }}
                            />
                          </FormControl>
                        </Row>
                        <Row>
                          <FormControl
                            style={{
                              width: "100%",
                              marginBottom: "20px",
                            }}
                          >
                            <TextField
                              sx={{
                                input: { color: "white" },
                              }}
                              id="outlined-search"
                              name="city"
                              required
                              value={formData.city}
                              onChange={handleChange}
                              placeholder="City"
                              variant="filled"
                              InputProps={{
                                classes: {
                                  underline:
                                    "MuiInput-underline-white MuiInput-onboardingform",
                                },
                                inputProps: {
                                  style: {
                                    textAlign: "center",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                  },
                                },
                              }}
                            />
                          </FormControl>
                        </Row>
                        <Row>
                          <FormControl
                            style={{
                              width: "100%",
                              marginBottom: "20px",
                            }}
                          >
                            <TextField
                              sx={{
                                input: { color: "white" },
                              }}
                              id="outlined-search"
                              name="country"
                              required
                              value={formData.country}
                              onChange={handleChange}
                              placeholder="Country"
                              variant="filled"
                              InputProps={{
                                classes: {
                                  underline:
                                    "MuiInput-underline-white MuiInput-onboardingform",
                                },
                                inputProps: {
                                  style: {
                                    textAlign: "center",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                  },
                                },
                              }}
                            />
                          </FormControl>
                        </Row>
                        <Row>
                          <FormControl
                            style={{
                              width: "100%",
                              marginBottom: "20px",
                            }}
                          >
                            <TextField
                              sx={{
                                input: { color: "white" },
                              }}
                              id="outlined-search"
                              name="phoneNumber"
                              type="number"
                              required
                              value={formData.phoneNumber}
                              onChange={handleChange}
                              placeholder="Phone Number"
                              variant="filled"
                              InputProps={{
                                classes: {
                                  underline:
                                    "MuiInput-underline-white MuiInput-onboardingform",
                                },
                                inputProps: {
                                  style: {
                                    textAlign: "center",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                  },
                                },
                              }}
                            />
                          </FormControl>
                        </Row>
                        <Row>
                          <FormControl
                            style={{
                              width: "100%",
                              marginBottom: "20px",
                            }}
                          >
                            <TextField
                              sx={{
                                input: { color: "white" },
                              }}
                              id="outlined-search"
                              name="company"
                              required
                              value={formData.company}
                              onChange={handleChange}
                              placeholder="Company"
                              variant="filled"
                              InputProps={{
                                classes: {
                                  underline:
                                    "MuiInput-underline-white MuiInput-onboardingform",
                                },
                                inputProps: {
                                  style: {
                                    textAlign: "center",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                  },
                                },
                              }}
                            />
                          </FormControl>
                        </Row>
                        <Row>
                          <FormControl
                            style={{
                              width: "100%",
                              marginBottom: "20px",
                            }}
                          >
                            <TextField
                              sx={{
                                input: { color: "white" },
                              }}
                              id="outlined-search"
                              name="position"
                              value={formData.position}
                              onChange={handleChange}
                              placeholder="Position (Optional)"
                              variant="filled"
                              InputProps={{
                                classes: {
                                  underline:
                                    "MuiInput-underline-white MuiInput-onboardingform",
                                },
                                inputProps: {
                                  style: {
                                    textAlign: "center",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                  },
                                },
                              }}
                            />
                          </FormControl>
                        </Row>
                        {type === "Buyer" && (
                          <Row
                            style={{
                              marginBottom: "10px",
                              textAlign: "center",
                              marginTop: "30px",
                            }}
                          >
                            <h2
                              className="onboardingform-heading"
                              style={{ color: "#white", fontSize: "28px" }}
                            >
                              Client Type
                            </h2>
                            <Col>
                              <Row style={{ marginBottom: "25px" }}>
                                <p
                                  style={{
                                    color: "white",
                                    fontSize: "25px",
                                    fontFamily:
                                      '"Helvetica-Neue-Light", sans-serif',
                                    opacity: "0.9",
                                    paddingLeft: "0",
                                  }}
                                >
                                  Please provide information about the type of
                                  deals you are looking to get involved in.
                                </p>
                              </Row>
                              <Row
                                className="justify-content-center"
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  marginBottom: "25px",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                <FormControl
                                  style={{ width: "100%", border: 0 }}
                                >
                                  <Select
                                    id="sector"
                                    className="onboarding-form-select"
                                    value={formData.clientType ?? undefined}
                                    onChange={handleClientTypeChange}
                                    variant="filled"
                                    sx={{ color: "white" }}
                                  >
                                    {answeredQuestionnaire.length > 1 &&
                                      !loading &&
                                      answeredQuestionnaire
                                        .filter((entry) => entry.id === 1)[0]
                                        .questionnaire?.answers?.map(
                                          (answer, index) => (
                                            <MenuItem
                                              key={index}
                                              value={answer.id}
                                            >
                                              {answer.answer}
                                            </MenuItem>
                                          )
                                        )}
                                  </Select>
                                </FormControl>
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </Col>
                      {type === "Buyer" && (
                        <Col
                          className="contact-us-col"
                          style={{
                            paddingLeft: "10%",
                            paddingRight: "10%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Row
                            style={{
                              marginBottom: "10px",
                              textAlign: "center",
                              marginTop: "20px",
                            }}
                          >
                            <h2
                              className="onboardingform-heading"
                              style={{ color: "#white", fontSize: "28px" }}
                            >
                              Which sectors are of interest?
                            </h2>
                          </Row>
                          <Row style={{ marginBottom: "25px" }}>
                            <p
                              style={{
                                color: "white",
                                fontSize: "25px",
                                fontFamily:
                                  '"Helvetica-Neue-Light", sans-serif',
                                opacity: "0.9",
                              }}
                            >
                              Please select all the relevant sectors that may
                              interest you.
                            </p>
                          </Row>
                          <Row style={{ marginBottom: "40px" }}>
                            {answeredQuestionnaire.length > 1 &&
                              !loading &&
                              answeredQuestionnaire
                                .filter((entry) => entry.id === 2)[0]
                                .questionnaire?.answers?.map(
                                  (answer, index) => (
                                    <Col xs={12} key={index}>
                                      <FormControl
                                        style={{
                                          width: "100%",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        <Button
                                          className={
                                            formData.sectorsOfInterest.includes(
                                              answer.answer
                                            )
                                              ? "MuiInput-underline-white onboardingForm-button active-onboardingForm-button"
                                              : "MuiInput-underline-white onboardingForm-button"
                                          }
                                          name={answer.answer.toLowerCase()}
                                          id="outlined-search"
                                          onClick={() =>
                                            handleStringClick(
                                              answer.answer,
                                              "sectorsOfInterest"
                                            )
                                          }
                                        >
                                          {answer.answer}
                                        </Button>
                                      </FormControl>
                                    </Col>
                                  )
                                )}
                          </Row>
                        </Col>
                      )}
                      {type === "Seller" && (
                        <Col
                          className="contact-us-col"
                          style={{
                            paddingLeft: "10%",
                            paddingRight: "10%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Row
                            style={{
                              marginBottom: "10px",
                              textAlign: "center",
                            }}
                          >
                            <h2
                              className="onboardingform-heading"
                              style={{
                                color: "white",
                                fontSize: "28px",
                                marginBottom: "25px",
                              }}
                            >
                              Registration Number
                            </h2>
                            <Row
                              className="justify-content-center"
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginBottom: "25px",
                                textAlign: "center",
                                width: "100%",
                              }}
                            >
                              <FormControl
                                style={{
                                  width: "100%",
                                  marginBottom: "20px",
                                }}
                              >
                                <TextField
                                  sx={{
                                    input: { color: "white" },
                                  }}
                                  id="outlined-search"
                                  name="registartionNumber"
                                  value={formData.registartionNumber}
                                  onChange={handleChange}
                                  placeholder="Registration Number"
                                  required
                                  variant="filled"
                                  InputProps={{
                                    classes: {
                                      underline:
                                        "MuiInput-underline-white MuiInput-onboardingform",
                                    },
                                    inputProps: {
                                      style: {
                                        textAlign: "center",
                                        paddingTop: "15px",
                                        paddingBottom: "15px",
                                      },
                                    },
                                  }}
                                />
                              </FormControl>
                            </Row>
                          </Row>
                          <Row
                            style={{
                              marginBottom: "10px",
                              textAlign: "center",
                              flexGrow: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <h2
                              className="onboardingform-heading"
                              style={{
                                color: "white",
                                fontSize: "28px",
                                marginBottom: "25px",
                                flexShrink: 0,
                              }}
                            >
                              How would you describe your business?
                            </h2>
                            <Row
                              className="justify-content-center"
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginBottom: "25px",
                                textAlign: "center",
                                width: "100%",
                                flexGrow: 1,
                                display: "flex",
                              }}
                            >
                              <FormControl
                                style={{
                                  width: "100%",
                                  marginBottom: "20px",
                                  height: "100%",
                                  display: "flex",
                                }}
                              >
                                <TextareaAutosize
                                  id="outlined-search"
                                  name="businessDescription"
                                  value={formData.businessDescription}
                                  onChange={handleChange}
                                  placeholder="Enter the answer here (Optional)"
                                  style={{
                                    color: "white",
                                    flexGrow: 1,
                                    padding: "15px",
                                    width: "100%",
                                    backgroundColor: "rgb(16, 19, 36)",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                    resize: "none",
                                    boxSizing: "border-box",
                                    overflowY: "auto",
                                  }}
                                />
                              </FormControl>
                            </Row>
                          </Row>
                        </Col>
                      )}
                    </Row>
                    <Row
                      className="d-block d-sm-none"
                      style={{
                        display: "flex", // Flexbox for horizontal alignment
                        flexDirection: "row", // Ensures buttons are aligned in a row
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "35px",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        className="redefined-button-white"
                        style={{ height: "70px", width: "209px" }}
                      >
                        Next <ArrowForwardIosIcon />
                      </Button>
                    </Row>
                  </Col>
                  <Col
                    className="d-none d-sm-flex"
                    xs="auto"
                    style={{
                      paddingTop: "2%",
                      paddingRight: "2%",
                      marginBottom: "50px",
                    }}
                  >
                    <Row>
                      <Button type="submit" style={{ color: "white" }}>
                        <ArrowForwardIosIcon />
                      </Button>
                    </Row>
                  </Col>
                </Form>
                <Toast
                  show={showToast}
                  onClose={() => setShowToast(false)}
                  style={{
                    position: "fixed",
                    bottom: 20,
                    right: 20,
                    backgroundColor: "#d9534f",
                    color: "white",
                  }}
                >
                  <Toast.Body>{errorMessage}</Toast.Body>
                </Toast>
              </>
            )}
          </>
        );
      case 2:
        return (
          <>
            {loading ? (
              <Row
                className="justify-content-center"
                style={{
                  marginTop: "50px",
                  marginBottom: "50px",
                  flex: "1",
                }}
              >
                <Spinner animation="border" variant="primary" />
              </Row>
            ) : (
              <Form onSubmit={handleSubmitTwo} style={{ display: "flex" }}>
                <Col
                  xs="auto"
                  style={{
                    paddingTop: "2%",
                    marginBottom: "50px",
                  }}
                >
                  <Row className="d-none d-sm-flex">
                    <Button style={{ color: "white" }} onClick={prevStep}>
                      <ArrowBackIosNewIcon />
                    </Button>
                  </Row>
                </Col>
                {type === "Buyer" && (
                  <Col style={{width:"inherit"}}>
                    <Row style={{ display: "flex", flexDirection: "row" }}>
                      <Col
                        xs={12}
                        sm={6}
                        style={{ marginLeft: "1%", marginRight: "1%" }}
                      >
                        <Row
                          className="justify-content-center"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "2%",
                            marginBottom: "35px",
                            textAlign: "center",
                          }}
                        >
                          <Col style={{ marginRight: "1%" }}>
                            <Row>
                              <h2
                                className="onboardingform-heading"
                                style={{ color: "#white", fontSize: "28px" }}
                              >
                                Asset class and strategy of interest?
                              </h2>
                            </Row>
                            {answeredQuestionnaire.length > 1 &&
                              !loading &&
                              answeredQuestionnaire
                                .filter((entry) => entry.id === 3)[0]
                                .questionnaire?.answers?.map(
                                  (answer, index) => (
                                    <Row key={index}>
                                      <FormControl
                                        style={{
                                          width: "100%",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        <Button
                                          className={
                                            formData.assetStrategy.includes(
                                              answer.id
                                            )
                                              ? "MuiInput-underline-white onboardingForm-button active-onboardingForm-button"
                                              : "MuiInput-underline-white onboardingForm-button"
                                          }
                                          name={answer.answer.toLowerCase()}
                                          id="outlined-search"
                                          onClick={() =>
                                            handleNumberClick(
                                              answer.id,
                                              "assetStrategy"
                                            )
                                          }
                                        >
                                          {answer.answer}
                                        </Button>
                                      </FormControl>
                                    </Row>
                                  )
                                )}
                          </Col>
                        </Row>
                        <Col
                          style={{
                            marginRight: "1%",
                            paddingLeft: "2%",
                            paddingRight: "2%",
                          }}
                        >
                          <Row
                            className="justify-content-center"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%",
                              marginBottom: "35px",
                              textAlign: "center",
                            }}
                          >
                            <h2
                              className="onboardingform-heading"
                              style={{ color: "#white", fontSize: "28px" }}
                            >
                              Existing sectors you operate in
                            </h2>
                          </Row>

                          {answeredQuestionnaire.length > 1 &&
                            !loading &&
                            answeredQuestionnaire
                              .filter((entry) => entry.id === 10)[0]
                              .questionnaire?.answers?.map((answer, index) => (
                                <Row key={index}>
                                  <FormControl
                                    style={{
                                      width: "100%",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Button
                                      className={
                                        formData.existingSectors.includes(
                                          answer.answer
                                        )
                                          ? "MuiInput-underline-white onboardingForm-button active-onboardingForm-button"
                                          : "MuiInput-underline-white onboardingForm-button"
                                      }
                                      name={answer.answer.toLowerCase()}
                                      id="outlined-search"
                                      onClick={() =>
                                        handleStringClick(
                                          answer.answer,
                                          "existingSectors"
                                        )
                                      }
                                    >
                                      {answer.answer}
                                    </Button>
                                  </FormControl>
                                </Row>
                              ))}
                        </Col>
                      </Col>
                      <Col xs={12} sm={5}>
                        <Row
                          className="justify-content-center"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "2%",
                            marginBottom: "80px",
                            textAlign: "center",
                          }}
                        >
                          <Col>
                            <Row>
                              <h2
                                className="onboardingform-heading"
                                style={{ color: "#white", fontSize: "28px" }}
                              >
                                Ideal Transaction Size
                              </h2>
                            </Row>
                            <Row
                              className="onboardingform-range"
                              style={{
                                marginRight: "auto",
                                marginLeft: "auto",
                                marginTop: "25px",
                              }}
                            >
                              <Col>
                                <TextField
                                  className="onboardingform-from-to"
                                  value={formData.idealTransactionMin}
                                  name="idealTransactionMin"
                                  type="number"
                                  onChange={handleChange}
                                  required
                                  variant="outlined"
                                  placeholder="From"
                                  style={{ width: "100%", marginRight: "10px" }}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      fontFamily:
                                        '"Helvetica-Neue-Light", sans-serif',
                                    },
                                  }}
                                />
                              </Col>
                              <Col>
                                <TextField
                                  className="onboardingform-from-to"
                                  value={formData.idealTransactionMax}
                                  name="idealTransactionMax"
                                  type="number"
                                  required
                                  onChange={handleChange}
                                  variant="outlined"
                                  placeholder="To"
                                  style={{ width: "100%" }}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      fontFamily:
                                        '"Helvetica-Neue-Light", sans-serif',
                                    },
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row
                          className="justify-content-center"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "2%",
                            marginBottom: "80px",
                            textAlign: "center",
                          }}
                        >
                          <Col>
                            <h2
                              className="onboardingform-heading"
                              style={{ color: "#white", fontSize: "28px" }}
                            >
                              Ideal Investment Returns IRR%
                            </h2>
                            <Row
                              className="onboardingform-range"
                              style={{
                                marginRight: "auto",
                                marginLeft: "auto",
                                marginTop: "25px",
                              }}
                            >
                              <Col>
                                <TextField
                                  className="onboardingform-from-to"
                                  value={formData.idealInvestmentMin}
                                  name="idealInvestmentMin"
                                  type="number"
                                  placeholder="From"
                                  required
                                  onChange={handleChange}
                                  variant="outlined"
                                  style={{ width: "100%", marginRight: "10px" }}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      fontFamily:
                                        '"Helvetica-Neue-Light", sans-serif',
                                    },
                                  }}
                                />
                              </Col>
                              <Col>
                                <TextField
                                  className="onboardingform-from-to"
                                  value={formData.idealInvestmentMax}
                                  name="idealInvestmentMax"
                                  type="number"
                                  placeholder="To"
                                  required
                                  onChange={handleChange}
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      fontFamily:
                                        '"Helvetica-Neue-Light", sans-serif',
                                    },
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row
                          className="justify-content-center"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "2%",
                            marginBottom: "80px",
                            textAlign: "center",
                          }}
                        >
                          <Col>
                            <Row>
                              <h2
                                className="onboardingform-heading"
                                style={{ color: "#white", fontSize: "28px" }}
                              >
                                Ideal trailing twelve-month (TTM) revenue range?
                              </h2>
                            </Row>
                            <Row
                              className="onboardingform-range"
                              style={{
                                marginRight: "auto",
                                marginLeft: "auto",
                                marginTop: "25px",
                              }}
                            >
                              <Col>
                                <TextField
                                  className="onboardingform-from-to"
                                  value={formData.idealTTMMin}
                                  name="idealTTMMin"
                                  required
                                  placeholder="From"
                                  type="number"
                                  onChange={handleChange}
                                  variant="outlined"
                                  style={{ width: "100%", marginRight: "10px" }}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      fontFamily:
                                        '"Helvetica-Neue-Light", sans-serif',
                                    },
                                  }}
                                />
                              </Col>
                              <Col>
                                <TextField
                                  className="onboardingform-from-to"
                                  value={formData.idealTTMMax}
                                  name="idealTTMMax"
                                  required
                                  type="number"
                                  placeholder="To"
                                  onChange={handleChange}
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      fontFamily:
                                        '"Helvetica-Neue-Light", sans-serif',
                                    },
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row
                          className="justify-content-center"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "2%",
                            marginBottom: "80px",
                            textAlign: "center",
                          }}
                        >
                          <Col>
                            <h2
                              className="onboardingform-heading"
                              style={{ color: "#white", fontSize: "28px" }}
                            >
                              Ideal trailing twelve-month (TTM) profit range?
                            </h2>
                            <Row
                              className="onboardingform-range"
                              style={{
                                marginRight: "auto",
                                marginLeft: "auto",
                                marginTop: "25px",
                              }}
                            >
                              <Col>
                                <TextField
                                  className="onboardingform-from-to"
                                  value={formData.idealProfitMin}
                                  name="idealProfitMin"
                                  type="number"
                                  required
                                  onChange={handleChange}
                                  variant="outlined"
                                  placeholder="From"
                                  style={{ width: "100%", marginRight: "10px" }}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      fontFamily:
                                        '"Helvetica-Neue-Light", sans-serif',
                                    },
                                  }}
                                />
                              </Col>
                              <Col>
                                <TextField
                                  className="onboardingform-from-to"
                                  value={formData.idealProfitMax}
                                  name="idealProfitMax"
                                  type="number"
                                  required
                                  placeholder="To"
                                  onChange={handleChange}
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      fontFamily:
                                        '"Helvetica-Neue-Light", sans-serif',
                                    },
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
<Row
  className="justify-content-center"
  style={{
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2%",
    marginBottom: "80px",
    textAlign: "center",
  }}
>
  <Col style={{ width: "100%", maxWidth: "500px" }}>
    <Row>
      <h2
        className="onboardingform-heading"
        style={{
          color: "white", 
          fontSize: "28px"
        }}
      >
        Which countries of risk are of interest?
      </h2>
    </Row>
    <Row
      style={{
        width: "100%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "25px",
      }}
    >
      <FormControl style={{ width: "100%", border: 0 }}>
        <Select
          id="sector"
          className="onboarding-form-select"
          multiple
          value={formData.countriesOfInterest}
          onChange={handleCountries}
          required
          renderValue={(selected) => (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {selected.join(", ")}
            </div>
          )}
          variant="filled"
          sx={{ color: "white" }}
        >
          {answeredQuestionnaire.length > 1 &&
            !loading &&
            answeredQuestionnaire
              .filter((entry) => entry.id === 8)[0]
              .questionnaire?.answers?.map((name, index) => (
                <MenuItem key={index} value={name.answer}>
                  <Checkbox
                    checked={
                      formData.countriesOfInterest.indexOf(name.answer) > -1
                    }
                  />
                  <ListItemText primary={name.answer} />
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </Row>
  </Col>
</Row>


                        <Row
                          className="justify-content-center"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "2%",
                            marginBottom: "80px",
                            textAlign: "center",
                          }}
                        >
                          <Col style={{ width: "50%" }}>
                            <h2
                              className="onboardingform-heading"
                              style={{ color: "#white", fontSize: "28px" }}
                            >
                              Existing Net Worth or Assets Under Management
                            </h2>
                            <Row
                              className="onboardingform-range"
                              style={{
                                marginRight: "auto",
                                marginLeft: "auto",
                                marginTop: "25px",
                              }}
                            >
                              <Col>
                                <TextField
                                  className="onboardingform-from-to"
                                  value={formData.netWorthMin}
                                  name="netWorthMin"
                                  type="number"
                                  placeholder="From"
                                  onChange={handleChange}
                                  variant="outlined"
                                  required
                                  style={{ width: "100%", marginRight: "10px" }}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      fontFamily:
                                        '"Helvetica-Neue-Light", sans-serif',
                                    },
                                  }}
                                />
                              </Col>
                              <Col>
                                <TextField
                                  className="onboardingform-from-to"
                                  value={formData.netWorthMax}
                                  name="netWorthMax"
                                  placeholder="To"
                                  type="number"
                                  onChange={handleChange}
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      fontFamily:
                                        '"Helvetica-Neue-Light", sans-serif',
                                    },
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      className="justify-content-center"
                      style={{
                        display: "flex", // Flexbox for horizontal alignment
                        flexDirection: "row", // Ensures buttons are aligned in a row
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "35px",
                        textAlign: "center",
                      }}
                    >
                      <Col
                        className="d-block d-sm-none"
                        xs={12}
                        sm="auto"
                        style={{
                          padding: "0 10px", // Adds space between buttons
                          display: "flex", // Aligns the button in the center of the column
                          justifyContent: "center", // Centers the button horizontally
                          marginBottom: "25px",
                        }}
                      >
                        <Button
                          className="redefined-button-white"
                          style={{ height: "70px", width: "209px" }}
                          onClick={prevStep}
                        >
                          <ArrowBackIosNewIcon /> Back
                        </Button>
                      </Col>
                      <Col
                        xs={12}
                        sm="auto"
                        style={{
                          padding: "0 10px", // Adds space between buttons
                          display: "flex", // Aligns the button in the center of the column
                          justifyContent: "center", // Centers the button horizontally
                        }}
                      >
                        <Button
                          type="submit"
                          className="redefined-button-white"
                          style={{ height: "70px", width: "209px" }}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}

                {type === "Seller" && (
                  <>
                    <Col>
                      <Row style={{ display: "flex", flexDirection: "row" }}>
                        <Col>
                          <Row
                            className="justify-content-center"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%",
                              marginBottom: "35px",
                              textAlign: "center",
                            }}
                          >
                            <Col>
                              <h2
                                className="onboardingform-heading"
                                style={{ color: "#white", fontSize: "28px" }}
                              >
                                Business Website URL (Optional)
                              </h2>
                              <Row
                                className="onboardingform-range"
                                style={{
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  marginTop: "25px",
                                }}
                              >
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <TextField
                                    sx={{
                                      input: { color: "white" },
                                    }}
                                    id="outlined-search"
                                    name="businessWebsite"
                                    value={formData.businessWebsite}
                                    onChange={handleChange}
                                    placeholder="Enter the answer here"
                                    variant="filled"
                                    InputProps={{
                                      classes: {
                                        underline:
                                          "MuiInput-underline-white MuiInput-onboardingform",
                                      },
                                      inputProps: {
                                        style: {
                                          textAlign: "center",
                                          paddingTop: "15px",
                                          paddingBottom: "15px",
                                        },
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            className="justify-content-center"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%",
                              marginBottom: "35px",
                              textAlign: "center",
                            }}
                          >
                            <Col>
                              <h2
                                className="onboardingform-heading"
                                style={{ color: "#white", fontSize: "28px" }}
                              >
                                How much revenue have you made in the last 12
                                months? (Optional)
                              </h2>
                              <Row
                                className="onboardingform-range"
                                style={{
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  marginTop: "25px",
                                }}
                              >
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <TextField
                                    sx={{
                                      input: { color: "white" },
                                    }}
                                    id="outlined-search"
                                    type="number"
                                    name="revenueLastYear"
                                    value={formData.revenueLastYear}
                                    onChange={handleChange}
                                    placeholder="Enter the answer here"
                                    variant="filled"
                                    InputProps={{
                                      classes: {
                                        underline:
                                          "MuiInput-underline-white MuiInput-onboardingform",
                                      },
                                      inputProps: {
                                        style: {
                                          textAlign: "center",
                                          paddingTop: "15px",
                                          paddingBottom: "15px",
                                        },
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            className="justify-content-center"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%",
                              marginBottom: "35px",
                              textAlign: "center",
                            }}
                          >
                            <Col>
                              <h2
                                className="onboardingform-heading"
                                style={{ color: "#white", fontSize: "28px" }}
                              >
                                How much profit have you made in the last 12
                                months? (Optional)
                              </h2>
                              <Row
                                className="onboardingform-range"
                                style={{
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  marginTop: "25px",
                                }}
                              >
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <TextField
                                    sx={{
                                      input: { color: "white" },
                                    }}
                                    id="outlined-search"
                                    type="number"
                                    name="profitLastYear"
                                    value={formData.profitLastYear}
                                    onChange={handleChange}
                                    placeholder="Enter the answer here"
                                    variant="filled"
                                    InputProps={{
                                      classes: {
                                        underline:
                                          "MuiInput-underline-white MuiInput-onboardingform",
                                      },
                                      inputProps: {
                                        style: {
                                          textAlign: "center",
                                          paddingTop: "15px",
                                          paddingBottom: "15px",
                                        },
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            className="justify-content-center"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%",
                              marginBottom: "35px",
                              textAlign: "center",
                            }}
                          >
                            <Col>
                              <h2
                                className="onboardingform-heading"
                                style={{ color: "#white", fontSize: "28px" }}
                              >
                                What is the total Debt of the business currently
                                outstanding? (Optional)
                              </h2>
                              <Row
                                className="onboardingform-range"
                                style={{
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  marginTop: "25px",
                                }}
                              >
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <TextField
                                    sx={{
                                      input: { color: "white" },
                                    }}
                                    id="outlined-search"
                                    type="number"
                                    name="totalDebt"
                                    value={formData.totalDebt}
                                    onChange={handleChange}
                                    placeholder="Enter the answer here"
                                    variant="filled"
                                    InputProps={{
                                      classes: {
                                        underline:
                                          "MuiInput-underline-white MuiInput-onboardingform",
                                      },
                                      inputProps: {
                                        style: {
                                          textAlign: "center",
                                          paddingTop: "15px",
                                          paddingBottom: "15px",
                                        },
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Row
                            className="justify-content-center w-100"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%",
                              marginBottom: "35px",
                              textAlign: "center",
                            }}
                          >
                            <Col>
                              <h2
                                className="onboardingform-heading"
                                style={{ color: "#white", fontSize: "28px" }}
                              >
                                How many employees does your company have?
                                (Optional)
                              </h2>
                              <Row
                                className="onboardingform-range"
                                style={{
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  marginTop: "25px",
                                }}
                              >
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <TextField
                                    sx={{
                                      input: { color: "white" },
                                    }}
                                    id="outlined-search"
                                    type="number"
                                    name="numberOfEmployees"
                                    value={formData.numberOfEmployees}
                                    onChange={handleChange}
                                    placeholder="Enter the answer here"
                                    variant="filled"
                                    InputProps={{
                                      classes: {
                                        underline:
                                          "MuiInput-underline-white MuiInput-onboardingform",
                                      },
                                      inputProps: {
                                        style: {
                                          textAlign: "center",
                                          paddingTop: "15px",
                                          paddingBottom: "15px",
                                        },
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            className="justify-content-center w-100"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%",
                              marginBottom: "35px",
                              textAlign: "center",
                            }}
                          >
                            <Col>
                              <h2
                                className="onboardingform-heading"
                                style={{ color: "#white", fontSize: "28px" }}
                              >
                                Asking price starting point (Minimum) (Optional)
                              </h2>
                              <Row
                                className="onboardingform-range"
                                style={{
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  marginTop: "25px",
                                }}
                              >
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <TextField
                                    sx={{
                                      input: { color: "white" },
                                    }}
                                    id="outlined-search"
                                    type="number"
                                    name="askingPrice"
                                    value={formData.askingPrice}
                                    onChange={handleChange}
                                    placeholder="Enter the answer here"
                                    variant="filled"
                                    InputProps={{
                                      classes: {
                                        underline:
                                          "MuiInput-underline-white MuiInput-onboardingform",
                                      },
                                      inputProps: {
                                        style: {
                                          textAlign: "center",
                                          paddingTop: "15px",
                                          paddingBottom: "15px",
                                        },
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            className="justify-content-center w-100"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%",
                              marginBottom: "35px",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              flexGrow: 1,
                            }}
                          >
                            <h2
                              className="onboardingform-heading"
                              style={{
                                color: "#white",
                                fontSize: "28px",
                                marginBottom: "25px",
                                flexShrink: 0,
                              }}
                            >
                              Specific Requirements
                            </h2>
                            <Row
                              className="justify-content-center"
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginBottom: "25px",
                                textAlign: "center",
                                width: "100%",
                                flexGrow: 1,
                                display: "flex",
                              }}
                            >
                              <FormControl
                                style={{
                                  width: "100%",
                                  marginBottom: "20px",
                                  display: "flex",
                                }}
                              >
                                <TextareaAutosize
                                  id="outlined-search"
                                  name="specificReqs"
                                  value={formData.specificReqs}
                                  onChange={handleChange}
                                  placeholder="Enter the answer here (Optional)"
                                  style={{
                                    color: "white",
                                    flexGrow: 1,
                                    padding: "15px",
                                    width: "100%",
                                    backgroundColor: "rgb(16, 19, 36)",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                    resize: "none",
                                    boxSizing: "border-box",
                                    overflowY: "auto",
                                  }}
                                />
                              </FormControl>
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        className="justify-content-center"
                        style={{
                          display: "flex", // Flexbox for horizontal alignment
                          flexDirection: "row", // Ensures buttons are aligned in a row
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "35px",
                          textAlign: "center",
                        }}
                      >
                        <Col
                          className="d-block d-sm-none"
                          xs={12}
                          sm="auto"
                          style={{
                            padding: "0 10px", // Adds space between buttons
                            display: "flex", // Aligns the button in the center of the column
                            justifyContent: "center", // Centers the button horizontally
                            marginBottom: "25px",
                          }}
                        >
                          <Button
                            className="redefined-button-white"
                            style={{ height: "70px", width: "209px" }}
                            onClick={prevStep}
                          >
                            <ArrowBackIosNewIcon /> Back
                          </Button>
                        </Col>
                        <Col
                          xs={12}
                          sm="auto"
                          style={{
                            padding: "0 10px", // Adds space between buttons
                            display: "flex", // Aligns the button in the center of the column
                            justifyContent: "center", // Centers the button horizontally
                          }}
                        >
                          <Button
                            type="submit"
                            className="redefined-button-white"
                            style={{ height: "70px", width: "209px" }}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Form>
            )}
            <Toast
              show={showToast}
              onClose={() => setShowToast(false)}
              style={{
                position: "fixed",
                bottom: 20,
                right: 20,
                backgroundColor: "#d9534f",
                color: "white",
              }}
            >
              <Toast.Body>{errorMessage}</Toast.Body>
            </Toast>
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        //seller
        const updatedQuestionnaire = await QuestionnaireUtility.answerGenerator(
          questionnaire,
          token
        );
        if (type === "Buyer") {
          const updatedQuestionnaireEntry = updatedQuestionnaire.find(
            (entry) => entry.id === 1
          );

          if (
            updatedQuestionnaireEntry &&
            updatedQuestionnaireEntry.questionnaire?.answers?.length
          ) {
            const firstAnswer =
              updatedQuestionnaireEntry.questionnaire.answers[0];
            formData.clientType = firstAnswer.id;
          }

          setAnsweredQuestionnaire(
            updatedQuestionnaire.filter((entry) => entry.role === "Buyer")
          );
        } else if (type === "Seller") {
          setAnsweredQuestionnaire(
            updatedQuestionnaire.filter((entry) => entry.role === "Seller")
          );
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Row
      className="justify-content-center contact-us-row"
      style={{ margin: "0", padding: "0", width: "100%" }}
      fluid
    >
      {renderForm()}
    </Row>
  );
};

export default OnboardingForm;
