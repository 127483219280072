import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import AboutUsBg from "../assets/about-us-bg.jpg";
import "./../styles/AboutUs.css";
import CircleIcon from "../components/CircleIcon/CircleIcon";
import { ShopWindow, People, Lightbulb } from "react-bootstrap-icons";
import ScrollingSections from "../components/ScrollingSections/ScrollingSections";
import logo from "../assets/logo-isolated.png";
import Footer from "../components/Footer/Footer";
import ContactUs from "../components/ContactUs/ContactUs";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import FooterMobile from "../components/Footer/FooterMobile";

function AboutUsNew() {
  const titles = [
    { title: "Title 1", text: "Text 1" },
    { title: "Title 2", text: "Text 2" },
    { title: "Title 3", text: "Text 3" },
    // Add more title-text pairs as needed
  ];

  interface Section {
    title: string;
    text: string;
  }

  const sectionsAbout: Section[] = [
    {
      title: "Why Choose Ependisis",
      text: "Ependisis is dedicated to meeting the unique needs of each client through expert guidance and a broad range of services. Our extensive industry experience and commitment to quality ensure access to exceptional opportunities.",
    },
  ];

  const sectionsServices: Section[] = [
    {
      title: "Deal Origination and Aggregation",
      text: "Our team excels in finding and consolidating opportunities and pockets of value across various sectors. We focus on thorough evaluation and vetting processes, providing our clients with well-researched options that align with their objectives.",
    },
    {
      title: "Investor Matching and Facilitation",
      text: "Ependisis connects clients with opportunities that best match their criteria. We support clients throughout the transaction process, ensuring a smooth and efficient experience.",
    },
    {
      title: "Transparent Deal Insights and Analytics",
      text: "We prioritize transparency, offering comprehensive insights and straightforward analytics for each opportunity.",
    },
    {
      title: "Personalized Portfolio Diversification",
      text: "Ependisis aids clients in creating diversified portfolios that suit their individual corporate needs.",
    },
  ];
  return (
    <Container
      fluid
      className="wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Row>
        <Col
          md={6}
          style={{
            position: "relative",
            width: "100%",
            height: "100vh%",
            backgroundImage: `url(${AboutUsBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Row
            className="d-none d-sm-flex justify-content-center"
            style={{ backgroundColor: "#101324" }}
          >
            <HeaderMenu />
          </Row>
          <Row
            className="d-block d-sm-none justify-content-center"
            style={{
              backgroundColor: "#101324",
            }}
          >
            <HeaderMenuMobile />
          </Row>
          <Row
            style={{ marginLeft: "7%", marginTop: "3%", lineHeight: "30px" }}
          >
            <Col
              xs={12} // Full width on extra small screens (mobile)
              md={6} // Half width on medium and larger screens (desktop)
              style={{ marginBottom: "4%" }}
            >
              <h2
                className="home-caption white-color"
                style={{ textAlign: "left" }}
              >
                Empowering
              </h2>
              <h2
                className="home-caption white-color"
                style={{ textAlign: "left" }}
              >
                Investors,
              </h2>
              <h2
                className="home-caption white-color"
                style={{ textAlign: "left" }}
              >
                Connecting
              </h2>
              <h2
                className="home-caption white-color"
                style={{ textAlign: "left" }}
              >
                Opportunities
              </h2>
              <p className="banner-paragraph" style={{ marginTop: "5%" }}>
                At Ependisis, we are revolutionizing the private equity
                landscape by creating a dynamic marketplace that empowers
                investors and connects buyers and sellers. Our mission is to
                provide a transparent and reliable platform that facilitates
                successful private equity transactions. With our team of
                industry experts and cutting-edge technology, we strive to
                unlock the full potential of private equity deals for our valued
                users.
              </p>
            </Col>
            <Col
              xs={0} // Hidden on extra small screens
              md={6} // Visible and takes up half the width on medium and larger screens
            ></Col>
          </Row>

          <Row
            className="justify-content-md-center"
            style={{ backgroundColor: "white", paddingBottom: "3%" }}
          >
            <Col xs={12} md={4} style={{ marginTop: "50px", display: "grid" }}>
              <div
                style={{
                  width: "85%",
                  height: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                }}
              >
                <div>
                  <CircleIcon icon={ShopWindow} color="blue" size="3x" />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "60px",
                  }}
                >
                  <h3 className="aboutUsTitle"> Marketplace</h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  <p className="aboutUsParagraph">
                    {" "}
                    Ependisis.com is an online marketplace with wide a range of
                    investor, including strategic buyers, venture capitalists,
                    angel investors, private equity funds, family offices and
                    individuals seeking entrepreneurial opportunities.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "50px", display: "grid" }}>
              <div
                style={{
                  width: "85%",
                  height: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                }}
              >
                <div>
                  <CircleIcon icon={People} color="white" size="3x" />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "60px",
                  }}
                >
                  <h3 className="aboutUsTitle"> Corporate Services</h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  <p className="aboutUsParagraph">
                    {" "}
                    Our commitment extends beyond providing a platform for
                    investors. We offer specialized corporate advisory services
                    designed to assist sellers in strategically positioning
                    their companies. This tailored guidance aims to optimize
                    their market presence and attract the right investors for
                    mutually beneficial transactions.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "50px", display: "grid" }}>
              <div
                style={{
                  width: "85%",
                  height: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                }}
              >
                <div>
                  <CircleIcon icon={Lightbulb} color="blue" size="3x" />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "60px",
                  }}
                >
                  <h3 className="aboutUsTitle"> Strategic Advisors</h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  <p className="aboutUsParagraph">
                    {" "}
                    We serve a crucial role as strategic advisors for both local
                    and international institutional investors. Our team provides
                    comprehensive insights and guidance, aiding investors in
                    making well-informed decisions that align with their
                    strategic objectives.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row
            className="justify-content-md-center"
            style={{ backgroundColor: "white", padding: "0" }}
          >
            <Col>
              <Row style={{margin:"auto", padding: "0"}} className="justify-content-md-center subscribe-row">
                <Col
                  xs={12} // Full width on mobile
                  md={5}
                  className="d-flex flex-column align-items-start" // Added flex for alignment
                  style={{
                    paddingLeft: "10%",
                    paddingRight: "10%", // Adjusting the padding for consistency on mobile
                    marginTop: "3%",
                    paddingBottom: "4%",
                  }}
                >
                  <h3 className="subscribe-now-heading">
                    Don't miss the next opportunity.
                  </h3>
                  <h3 className="subscribe-now-heading">
                    Sign up for our newsletter.
                  </h3>
                </Col>
                <Col
                  xs={12} // Full width on mobile
                  md={5}
                  className="d-flex flex-column justify-content-center" // Aligning contents better
                  style={{
                    marginTop: "3%", // Adjust top margin for spacing
                    paddingLeft: "10%",
                    paddingRight: "10%", // Ensures equal padding on mobile
                    paddingBottom: "4%",
                  }}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Recipient's username"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      id="subscribe-input"
                    />
                    <Button
                      variant="outline-secondary"
                      id="subscribe-button"
                      style={{}}
                    >
                      Subscribe
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            className="justify-content-md-center"
            style={{
              backgroundColor: "white",
              paddingTop: "3%",
              paddingLeft: "4%",
              paddingRight: "4%",
              display: "flex",
            }}
          >
            <Row className="justify-content-md-center">
              <Col
                xs={12}
                md={3}
                className="section-scrolling-category-separator w-100-mobile p-0-mobile ml-8-mobile"
              >
                <div>
                  <h3 className="section-scrolling-category">About</h3>
                </div>
              </Col>
              <Col
                xs={12}
                md={9}
                className="w-100-mobile p-0-mobile ml-8-mobile"
                style={{ paddingLeft: "2%", paddingRight: "8%" }}
              >
                <div>
                  {/* <h1 id="about-sections-title">Why choose Ependisis</h1> */}
                  <ScrollingSections sections={sectionsAbout} />
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col
                xs={12}
                md={3}
                className="section-scrolling-category-separator w-100-mobile p-0-mobile ml-8-mobile"
              >
                {" "}
                <div>
                  <h3 className="section-scrolling-category">Services</h3>
                </div>
              </Col>
              <Col
                xs={8}
                md={9}
                className="w-100-mobile p-0-mobile ml-8-mobile"
                style={{ paddingLeft: "2%", paddingRight: "8%" }}
              >
                <div className="app">
                  {/* <h1 id="about-sections-title">Why choose Ependisis</h1> */}
                  <ScrollingSections sections={sectionsServices} />
                </div>
              </Col>
            </Row>
          </Row>
          <Row
            className="justify-content-center"
            style={{ paddingTop: "5%", backgroundColor: "white" }}
          >
            <Col className="d-flex align-items-center justify-content-center">
              <div>
                <img
                  src={logo}
                  alt="Logo"
                  style={{
                    width: "50%",
                    height: "auto",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row
            className="justify-content-center"
            style={{ paddingTop: "5%", backgroundColor: "white" }}
          >
            <ContactUs />
          </Row>
          <Row
            className="d-none d-sm-flex justify-content-center"
            style={{
              paddingLeft: "1%",
              paddingRight: "1%",
              paddingTop: "1%",
              backgroundColor: "white",
            }}
          >
            <Footer />
          </Row>
          <Row
            className="d-block d-sm-none justify-content-center"
            style={{
              paddingLeft: "2%",
              paddingRight: "2%",
              paddingTop: "4%",
              backgroundColor: "white",
            }}
          >
            <FooterMobile />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUsNew;
