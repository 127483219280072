import { Col, Row } from "react-bootstrap";
import "./Footer.css";
import logo from "../../assets/logo-black-letters.png";

const Footer: React.FC = () => {
  return (
    <>
      <Row className="footer-row" fluid>
        <Col>
          <Row
            className="link-footer"
            style={{ fontSize: "27px", color: "#2F3B68" }}
          >
            Useful Links
          </Row>
          <Row
            className="link-footer"
            style={{ fontSize: "18px", color: "#000000", marginTop: "4%" }}
          >
            <a href="/privacypolicy">Privacy Policy</a>
          </Row>
          <Row
            className="link-footer"
            style={{ fontSize: "18px", color: "#000000" }}
          >
            <a href="/cookiespolicy">Cookies Policy</a>
          </Row>
          <Row
            className="link-footer"
            style={{ fontSize: "18px", color: "#000000" }}
          >
            <a href="/termsandconditions">Terms and Condition</a>
          </Row>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
        </Col>
        <Col className="d-flex flex-column justify-content-start">
          <Row
            className="info-footer"
            style={{ fontSize: "18px", color: "#000000" }}
          >
            <a href="mailto:info@ependisis.com">info@ependisis.com</a>
          </Row>
          <Row
            className="info-footer"
            style={{ fontSize: "18px", color: "#000000", marginTop: "4%" }}
          >
            <a href="https://maps.app.goo.gl/HTgpzWAnNNJzLesU6">
              Gladstonos 31
            </a>
          </Row>
          <Row
            className="info-footer"
            style={{ fontSize: "18px", color: "#000000" }}
          >
            <a href="https://maps.app.goo.gl/HTgpzWAnNNJzLesU6">1095 Nicosia</a>
          </Row>
          <Row
            className="info-footer"
            style={{ fontSize: "18px", color: "#000000" }}
          >
            Cyprus
          </Row>
          <Row
            className="closing-footer"
            style={{ marginTop: "8%", marginLeft: "auto" }}
          >
            {/* Ependisis.com © 2024 */}
          </Row>
        </Col>
      </Row>
      <Row
        className="closing-footer"
        style={{
          fontSize: "14px",
          marginTop: "0",
          opacity: 0.8,
          fontStyle: "italic",
        }}
      >
        <Col
          xs={12}
          className="mb-3 mb-sm-0"
          style={{ fontSize: "14px", padding: 0, lineHeight: 1.2 }}
        >
          Ependisis.com is a technology and marketing company that facilitates a
          private marketplace. This platform is not open to retail investors and
          should not be construed as an invitation to invest. The content and
          services provided on this website are for informational purposes only.
          Ependisis.com does not offer investment advice, make recommendations,
          or hold client funds. All users are encouraged to seek professional
          advice before making any investment decisions.
        </Col>
        <Col
          xs={12}
          sm="auto"
          className="text-center text-sm-left"
          style={{ fontSize: "14px", padding: 0 }}
        >
          Ependisis.com © 2024
        </Col>
      </Row>
    </>
  );
};

export default Footer;
