import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import AuthorizeWrapper from "../components/Auth/AuthorizeWrapper";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { useEffect, useRef, useState } from "react";
import { UserInfo, UserStatus, addUserInfo } from "../redux/UserInfoReducer";
import { getUserInfo } from "../auth/AuthUtils";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import OnboardingStepper from "../components/OnboardingStepper/OnboardingStepper";
import Footer from "../components/Footer/Footer";
import OnboardingForm from "../components/OnboardingForm/OnboardingForm";
import {
  UploadCustomerDocuments,
  GenerateVerification as EpendisisGenVerify,
} from "../apis/EpendisisApi";
import { useDispatch } from "react-redux";
import TickMarkImage from "../assets/check-mark-congratulations.jpg";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import FooterMobile from "../components/Footer/FooterMobile";
import OnboardingStepperMobile from "../components/OnboardingStepper/OnboardingStepperMobile";
import '../styles/Onboarding.css';

const OnboardingNew: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | number | boolean | object | null>(
    ""
  );
  const steps = ["Email Verification", "Questionnaire", "ID Verification"];
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [showToast, setShowToast] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [validationSuccess, setValidationSuccess] = useState(false);
  const formRef = useRef<HTMLDivElement | null>(null);

  const handleStatusUpdate = (newStatus: UserStatus) => {
    if (userInfo) {
      var updatedUserInfo = { ...userInfo };
      updatedUserInfo.status = newStatus;
      secureLocalStorage.setItem("user", JSON.stringify(updatedUserInfo));
      dispatch(addUserInfo(JSON.stringify(updatedUserInfo)));
      setUserInfo(updatedUserInfo);
    }
  };

  const handleRole = (role: string) => {
    switch (role) {
      case "PendingVerification":
        setActiveStep(0);
        break;

      case "PendingDetails":
        setActiveStep(1);
        break;

      case "PendingDocuments":
        setActiveStep(2);
        break;

      case "PendingApproval":
        setActiveStep(3);
        break;

      default:
        break;
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray: File[] = Array.from(e.target.files);
      const limitedFiles = filesArray.slice(0, 10);

      if (filesArray.length > 10) {
        setSelectedFiles([]);
        setShowToast(true);
      } else {
        setShowToast(false);
        setSelectedFiles(limitedFiles);
      }
    }
  };

  const handleEmailVerification = async () => {
    const response = await EpendisisGenVerify(email, token?.toString() ?? "");
    if (response == "success") {
      setValidationError(false);
      setValidationSuccess(true);
    } else {
      setValidationSuccess(false);
      setValidationError(true);
    }
  };

  const handleFileUpload = async () => {
    setSubmitting(true);

    const formData = new FormData();

    selectedFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });

    try {
      await UploadCustomerDocuments(formData, userInfo?.id?.toString() ?? "0");
      handleStatusUpdate(UserStatus.PendingApproval);
      handleRole(UserStatus.PendingApproval);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    document.title = "Onboarding";
    const fetchData = async () => {
      setLoading(true);
      try {
        const user = getUserInfo();
        const token = secureLocalStorage.getItem("token");
        setToken(token);
        if (user) {
          setUserInfo(user);
          setEmail(user.email);
          handleRole(user.status);
          const nameParts = user.name.split(" ");
          const firstName =
            nameParts[0]?.charAt(0).toUpperCase() +
            nameParts[0]?.slice(1).toLowerCase();
          setFirstName(firstName);
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <AuthorizeWrapper>
      <>
        <Container
          fluid
          style={{
            position: "relative",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        ><Col>
          <Row
            fluid
            className="d-none d-sm-flex justify-content-center w-100"
            style={{ backgroundColor: "#101324", width: "100%", margin: 0 }}
          >
            <HeaderMenu />
          </Row>
          <Row
            className="d-block d-sm-none justify-content-center"
            style={{
              backgroundColor: "#101324",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <HeaderMenuMobile />
          </Row>
          <Col
            className="w-100"
            style={{
              paddingTop: "30px",
              paddingBottom: "30px",
              flexGrow: "1",
              margin: 0,
            }}
          >
            <Row style={{ paddingLeft: "50px", paddingRight: "50px" }}>
              <h2
                style={{
                  fontFamily: '"Helvetica-Neue", sans-serif',
                  color: "#526ABF",
                  fontWeight: "bold",
                }}
                className="onboarding-caption"
              >
                Welcome {firstName}!
              </h2>
              <p className="onboarding-text">Follow the steps to sign up.</p>
            </Row>
            {loading ? (
              // Display a loading spinner while data is being fetched
              <Row
                className="justify-content-center"
                style={{ marginTop: "50px" }}
              >
                <Spinner animation="border" variant="primary" />
              </Row>
            ) : (
              <>
                <Row className="d-block d-sm-none">
                  <OnboardingStepperMobile
                    steps={steps}
                    currentStep={activeStep}
                  />
                </Row>
                <Row className="d-none d-sm-flex">
                  <OnboardingStepper steps={steps} currentStep={activeStep} />
                </Row>
                {activeStep === 0 && (
                  <>
                    {validationError === true && (
                      <Row
                        ref={formRef}
                        style={{
                          marginTop: "50px",
                          width: "73%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        className="justify-content-center"
                      >
                        <Col>
                          <div className="error-message">
                            <p> Something went wrong</p>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {validationSuccess === true && (
                      <Row
                        ref={formRef}
                        style={{
                          marginTop: "50px",
                          width: "73%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        className="justify-content-center"
                      >
                        <Col>
                          <div className="success-message">
                            <p> Email sent! Please check your email. </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row
                      className="justify-content-center text-center align-items-center"
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: "1",
                        paddingLeft: "50px",
                        paddingRight: "50px",
                      }}
                    >
                      <Row
                        className="justify-content-center"
                      >
                        <h2
                          style={{
                            color: "#3A4A90",
                            fontFamily: '"Helvetica-Neue", sans-serif',
                            fontWeight: "bold",
                          }}
                          className="onboarding-caption-paragraph"
                        >
                          Verify your email
                        </h2>
                      </Row>
                      <Row
                        className="justify-content-center"
                        style={{ width: "50%", display: "contents" }}
                      >
                        <p
                          style={{
                            color: "#272f53",
                            fontFamily: '"Helvetica-Neue-Light", sans-serif',
                            marginBottom: "16px",
                          }}
                          className="onboarding-paragraph"
                        >
                          We have sent an email to <b>{email}</b>. Please follow
                          the instructions on the email to continue the process.
                        </p>
                      </Row>
                      <Row
                        className="justify-content-center"
                        style={{ width: "45%", display: "contents" }}
                      >
                        <p
                          style={{
                            color: "#272f53",
                            fontSize: "20px",
                            fontFamily: '"Helvetica-Neue-Light", sans-serif',
                            marginBottom: "5px",
                          }}
                        >
                          Can't find it? Please check your spam folder.
                        </p>
                      </Row>
                      <Row
                        className="justify-content-center"
                        style={{ width: "40%", display: "contents" }}
                      >
                        <span
                          style={{
                            lineHeight: "16px",
                            opacity: "0.6",
                            color: "#272f53",
                            fontFamily: '"Helvetica-Neue-Light", sans-serif',
                            fontStyle: "italic",
                          }}
                          className="onboarding-note"
                        >
                          If you do not receive the email within two minutes,
                        </span>
                        <span
                          style={{
                            lineHeight: "16px",
                            opacity: "0.6",
                            color: "#272f53",
                            fontFamily: '"Helvetica-Neue-Light", sans-serif',
                            fontStyle: "italic",
                          }}
                          className="onboarding-note"
                        >
                          click the resend button below.
                        </span>
                      </Row>
                      <Row
                        style={{ marginTop: "30px" }}
                        className="justify-content-center"
                        onClick={handleEmailVerification}
                      >
                        <Button
                          className="redefined-button-purple"
                          style={{ height: "72px", width: "300px" }}
                        >
                          Resend Verification email
                        </Button>
                      </Row>
                    </Row>
                  </>
                )}
                {activeStep === 1 && userInfo !== null && (
                  <OnboardingForm
                    token={userInfo.token}
                    id={userInfo.id}
                    type={userInfo.roles}
                    handleStatusUpdate={handleStatusUpdate}
                    handleRole={handleRole}
                  />
                )}
                {activeStep === 2 && (
                  <Row
                    className="justify-content-center text-center align-items-center"
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: "1",
                      paddingLeft: "50px",
                      paddingRight: "50px",
                    }}
                  >
                    <Row
                      className="justify-content-center"
                      style={{ marginBottom: "24px" }}
                    >
                      <h2
                        style={{
                          color: "#3A4A90",
                          fontFamily: '"Helvetica-Neue", sans-serif',
                          fontSize: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        ID Verification
                      </h2>
                    </Row>
                    <Row
                      className="justify-content-center"
                      style={{ width: "50%", display: "contents" }}
                    >
                      {userInfo?.roles === "Seller" && (
                        <>
                          {" "}
                          <p
                            style={{
                              color: "#272f53",
                              fontSize: "20px",
                              fontFamily: '"Helvetica-Neue-Light", sans-serif',
                              marginBottom: "16px",
                            }}
                          >
                            To complete your profile, you will need to provide
                            proof of the following documents:
                          </p>
                          <div
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <ul style={{ textAlign: "left", fontSize: "18px" }}>
                              <li
                                style={{
                                  width: "auto",
                                  fontFamily:
                                    '"Helvetica-Neue-Light", sans-serif',
                                }}
                              >
                                <span>Certificate of Directors</span>
                              </li>
                              <li
                                style={{
                                  width: "auto",
                                  fontFamily:
                                    '"Helvetica-Neue-Light", sans-serif',
                                }}
                              >
                                <span>Certificate of Shareholders</span>
                              </li>
                              <li
                                style={{
                                  width: "auto",
                                  fontFamily:
                                    '"Helvetica-Neue-Light", sans-serif',
                                }}
                              >
                                <span>Certificate of Incorporation</span>
                              </li>
                              <li
                                style={{
                                  width: "auto",
                                  fontFamily:
                                    '"Helvetica-Neue-Light", sans-serif',
                                }}
                              >
                                <span>Certificate of Registered address</span>
                              </li>
                              <li
                                style={{
                                  width: "auto",
                                  fontFamily:
                                    '"Helvetica-Neue-Light", sans-serif',
                                }}
                              >
                                <span>
                                  IDs & Utility Bills of Directors and UBOs
                                </span>
                              </li>
                              <li
                                style={{
                                  width: "auto",
                                  fontFamily:
                                    '"Helvetica-Neue-Light", sans-serif',
                                }}
                              >
                                <span>
                                  Latest audited accounts or Management Accounts
                                </span>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                      {userInfo?.roles === "Buyer" && (
                        <>
                          {" "}
                          <p
                            style={{
                              color: "#272f53",
                              fontSize: "20px",
                              fontFamily: '"Helvetica-Neue-Light", sans-serif',
                              marginBottom: "16px",
                            }}
                          >
                            To complete your profile, you will need to provide
                            proof of identity.
                          </p>
                        </>
                      )}
                    </Row>
                    <Row
                      className="justify-content-center"
                      style={{ width: "45%", display: "contents" }}
                    >
                      <Form>
                        <Form.Group
                          controlId="formFileMultiple"
                          style={{
                            height: "38px",
                            marginBottom: "0",
                            overflow: "hidden",
                          }}
                        >
                          <label className="custom-file-input">
                            <Form.Control
                              type="file"
                              multiple
                              className="id-upload-react-input"
                              onChange={handleFileChange}
                              style={{
                                overflow: "hidden",
                                border: "0",
                              }}
                            />
                          </label>
                        </Form.Group>
                      </Form>
                    </Row>
                    <Row
                      className="justify-content-center"
                      style={{ width: "40%", display: "contents" }}
                    >
                      <span
                        style={{
                          lineHeight: "16px",
                          opacity: "0.6",
                          color: "#272f53",
                          fontSize: "16px",
                          fontFamily: '"Helvetica-Neue-Light", sans-serif',
                          fontStyle: "italic",
                        }}
                      >
                        You can select up to 10 files
                      </span>
                    </Row>
                    <Row
                      style={{ marginTop: "30px" }}
                      className="justify-content-center"
                    >
                      <Button
                        className="redefined-button-purple"
                        style={{ height: "72px", width: "300px" }}
                        disabled={selectedFiles.length === 0 || submitting}
                        onClick={handleFileUpload}
                      >
                        {submitting ? (
                          <span> Loading...</span>
                        ) : (
                          <span className="onboarding-button">
                            Start Verification
                          </span>
                        )}
                      </Button>
                    </Row>
                  </Row>
                )}
                {activeStep === 3 && (
                  <>
                    <Row className="justify-content-center">
                      <img
                        src={TickMarkImage}
                        alt="Congratulations!"
                        className="congratulations-tick"
                        style={{  marginTop: "50px" }}
                      />
                    </Row>
                    <Row className="text-center" style={{ marginTop: "12px" }}>
                      <h4 className="ff-lato" style={{ fontSize: "30px" }}>
                        {" "}
                        Congratulations!
                      </h4>
                    </Row>
                    <Row className="text-center" style={{ marginTop: "12px" }}>
                      <p
                        className="ff-lato"
                        style={{ fontSize: "16px", marginBottom: "0" }}
                      >
                        {" "}
                        The onboarding procedure has now been completed.
                      </p>
                      <p className="ff-lato" style={{ fontSize: "16px" }}>
                        You should be expecting an email once our team approves
                        your application.
                      </p>
                    </Row>
                  </>
                )}
              </>
            )}
          </Col>
          </Col>
        
        <Col
          className="d-none d-sm-flex justify-content-center"
          style={{
            boxShadow: "inset 0px 3px 6px #00000029",
            padding: "1%",
            backgroundColor: "white",
            width: "100%",
            marginTop: "1%",
          }}
        >
          <Footer />
        </Col>
        <Row
          className="d-block d-sm-none justify-content-center"
          style={{
            boxShadow:
              "inset 0px 3px 6px -3px #00000029, inset -3px 0px 0px #ffffff, inset 3px 0px 0px #ffffff",
            padding: "1%",
            backgroundColor: "white",
            width: "100%",
            marginLeft: "2%",
            marginRight: "2%",
            marginTop: "4%",
          }}
        >
          <FooterMobile />
        </Row>
        </Container>
      </>
    </AuthorizeWrapper>
  );
};

export default OnboardingNew;
