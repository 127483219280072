import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CarouselVideo from "../assets/carousel.mp4";
import logo from "../assets/logo-isolated.png";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import SummaryCard from "../components/SummaryCard/SummaryCard";
import HomePropertyCard from "../components/HomePropertyCard/HomePropertyCard";
import Footer from "../components/Footer/Footer";
import ContactUs from "../components/ContactUs/ContactUs";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../styles/mobile/home.css";
import AccordionContainer from "../containers/AccordionContainer";
import FooterMobile from "../components/Footer/FooterMobile";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";

function HomeMobile() {
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    const logoElement = document.getElementById("fade-out-logo");
    const fadeInRow = document.getElementById("fade-in-row");
    if (!transition && logoElement) {
      setTimeout(() => logoElement.classList.add("fade-out"), 10);
      setTimeout(() => setTransition(true), 1500);
    }
    if (transition && fadeInRow) {
      setTimeout(() => fadeInRow.classList.add("fade-in"), 500);
    }
  }, [transition]);

  const videoStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    minHeight: "100vh",
  };

  return (
    <>
      <Container
        fluid
        className="wrapper"
        style={{
          flexDirection: "column",
          minHeight: "100vh",
          height: "100%",
          padding: 0,
        }}
      >
        <Row>
          <Col
            xs={12}
            md={6}
            style={{ position: "relative", width: "100%", minHeight: "100vh" }}
          >
            <video className="videoTag" autoPlay loop muted style={videoStyle}>
              <source src={CarouselVideo} type="video/mp4" />
            </video>

            {/* Logo on Mobile and Desktop */}
            {!transition && (
              <Row
                className="initial-logo-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: 2,
                  width: "100%",
                  top: "40%",
                }}
              >
                <img
                  src={logo}
                  id="fade-out-logo"
                  style={{ height: "150px", width: "150px" }} // Adjusted for mobile
                  alt="Logo"
                />
              </Row>
            )}

            {/* Main Content */}
            <Row id="fade-in-row" style={{ position: "relative", zIndex: "2" }}>
              <Row className="d-none d-sm-flex justify-content-center">
                <HeaderMenu />
              </Row>
              <Row className="d-block d-sm-none justify-content-center">
                <HeaderMenuMobile />
              </Row>

              <Row
                style={{
                  width: "90%",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "20vh", // Adjust margin for mobile
                }}
              >
                <Col className="text-wrap w-100">
                  <h3 className="gif-caption">BUSINESS DEALS</h3>
                </Col>
              </Row>

              <Row
                style={{
                  width: "85%",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "10px",
                }}
              >
                <Col>
                  <h4 className="gif-p">
                    Your Premier Marketplace for Private Equity and <br /> M&A
                    Deals.
                  </h4>
                </Col>
              </Row>

              <Row
                style={{
                  width: "85%",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "35px",
                  marginBottom: "100px", // Adjust margin for mobile
                }}
              >
                <Col>
                  <a
                    href="/marketplace"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      width: "auto",
                    }}
                  >
                    <Button
                      className="redefined-button-white"
                      style={{ height: "50px", width: "250px" }} // Adjusted for mobile
                    >
                      Go to the Marketplace
                    </Button>
                  </a>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        {transition && (
          <>
            <Row
              style={{
                backgroundColor: "white",
                marginLeft: "4%",
                marginRight: "4%",
                marginTop: "4%",
              }}
            >
              <Col xs={12}>
                <h2 className="home-caption">What the platform does</h2>
              </Col>

              {/* Subcaption Section */}
              <Col xs={12} style={{ marginTop: "1%", paddingLeft: "1%" }}>
                <p className="home-subcaption">
                  A curated selection of deals tailored to your investment
                  preferences.
                </p>
              </Col>
              <Row
                style={{
                  marginTop: "2%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="justify-content-center w-100"
              >
                {/* Buyers Summary Card */}
                <Col
                  xs={12}
                  md={4}
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "20px" }}
                >
                  <SummaryCard
                    title="Buyers"
                    paragraphText="Discover the perfect deal to grow your portfolio or business and start due diligence effortlessly within minutes."
                    backgroundColor="#636FA0"
                  />
                </Col>

                {/* Marketplace Summary Card */}
                <Col
                  xs={12}
                  md={4}
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "20px" }}
                >
                  <SummaryCard
                    title="Marketplace"
                    paragraphText="We understand the importance of starting the right dialogue to foster a mutually beneficial transaction."
                    backgroundColor="#272F53"
                  />
                </Col>

                {/* Sellers Summary Card */}
                <Col
                  xs={12}
                  md={4}
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "20px" }}
                >
                  <SummaryCard
                    title="Sellers"
                    paragraphText="Unlock the potential of crafting an impeccable listing with the guidance of our expert team."
                    backgroundColor="#636FA0"
                  />
                </Col>
              </Row>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                marginLeft: "4%",
                marginRight: "4%",
                marginTop: "7%",
              }}
            >
              <Col xs={12}>
                <h2 className="home-caption">
                  Your Path to Private <br />
                  Equity Success
                </h2>
                {/* <h2 className="home-caption"></h2> */}
              </Col>
              <Col xs={12} style={{ marginTop: "2%" }}>
                <a
                  href="/Services"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "auto",
                  }}
                >
                  <Button
                    className="redefined-button-purple w-100-mobile"
                    style={{ height: "72px", width: "226px" }}
                  >
                    Services
                  </Button>
                </a>
              </Col>
            </Row>
            <Row
              className="d-block d-sm-none justify-content-center"
              style={{ marginLeft: "2%", marginRight: "2%", marginTop: "4%" }}
            >
              <AccordionContainer />
            </Row>
            <Row
              className="d-none d-sm-flex justify-content-center"
              style={{ marginLeft: "2%", marginRight: "2%", marginTop: "4%" }}
            >
              <Col>
                <HomePropertyCard
                  title="BUSINESS DEAL ACCESS"
                  paragraph="At Ependisis.com, our commitment to facilitating successful M&A transactions goes hand-in-hand with providing business deal access. Our platform ensures that investors have a broad spectrum of opportunities at their fingertips, enabling them to explore and engage with a diverse array of investment oppoerunities that align with their strategic goals."
                  titleColor="#272F53"
                  paragraphColor="272F53"
                />
              </Col>
              <Col>
                <HomePropertyCard
                  title="CUTTING-EDGE TECHNOLOGY"
                  paragraph="Driven by cutting-edge technology, Ependisis.com offers a seamless and user-friendly online marketplace experience. Our innovative platform leverages advanced technology to enhance user interactions, streamline processes, and provide real-time insights."
                  titleColor="#272F53"
                  paragraphColor="272F53"
                />
              </Col>
              <Col>
                <HomePropertyCard
                  title="TRANSPARENCY AND RELIABILITY"
                  paragraph="Transparency and reliability are at the epicenter of Ependisis.com offers. We prioritize open communication and provide a secure, trustworthy environment for all our users. Whether you are a seller looking to position your company strategically or an investor seeking opportunities, you can rely on our platform for transparent information to achieve your goals."
                  titleColor="#272F53"
                  paragraphColor="272F53"
                />
              </Col>
            </Row>
            <Row
              className="d-none d-sm-flex justify-content-center"
              style={{ marginLeft: "2%", marginRight: "2%", marginTop: "1%" }}
            >
              <Col>
                <HomePropertyCard
                  title="SUPPORTING GROWTH AND SUCCESS"
                  paragraph="Ependisis.com is more than just a marketplace; it is your partner in growth. Our platform is designed to support the success of both buyers and sellers by offering valuable resources, expert guidance, and a network of opportunities. Join us in fostering growth and achieving your investment goals through strategic and informed decision-making."
                  titleColor="#272F53"
                  paragraphColor="272F53"
                />
              </Col>
              <Col>
                <HomePropertyCard
                  title="NAVIGATING THE M&A LANDSCAPE"
                  paragraph="Navigate the complexities of the M&A landscape with confidence through Ependisis.com. Our platform equips you with the tools and insights needed to make informed decisions, mitigate risks, and capitalize on opportunities. Stay ahead in the dynamic world of mergers and acquisitions with our comprehensive support and resources."
                  titleColor="#272F53"
                  paragraphColor="272F53"
                />
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <ArrowForwardIosIcon style={{ fontSize: "100px" }} />
              </Col>
            </Row>
          </>
        )}
        {transition && (
          <Row
            style={{
              backgroundColor: "white",
              marginLeft: "4%",
              marginRight: "4%",
              marginTop: "7%",
            }}
          ></Row>
        )}
      </Container>
      <Container
        fluid
        className="justify-content-center"
        style={{
          marginTop: "5%",
          padding: 0,
        }}
      >
        <ContactUs />
      </Container>
      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{ marginLeft: "1%", marginRight: "1%", marginTop: "1%" }}
      >
        <Footer />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
        style={{ marginLeft: "2%", marginRight: "2%", marginTop: "4%" }}
      >
        <FooterMobile />
      </Row>
    </>
  );
}

export default HomeMobile;
