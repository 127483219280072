import React from 'react';
import { Accordion } from 'react-bootstrap';
import './AccordionContainer.css';

const AccordionContainer: React.FC = () => {
  return (
    <Accordion defaultActiveKey="0">
      {/* UNPARALLELED DEAL ACCESS */}
      <Accordion.Item className="mobile-accordion" eventKey="0">
        <Accordion.Header>UNPARALLELED DEAL ACCESS</Accordion.Header>
        <Accordion.Body>
          At Ependisis.com, our commitment to facilitating successful M&A transactions goes hand-in-hand with providing unparalleled deal access. Our platform ensures that investors have a broad spectrum of opportunities at their fingertips, enabling them to explore and engage with a diverse array of investment opportunities that align with their strategic goals.
        </Accordion.Body>
      </Accordion.Item>

      {/* CUTTING-EDGE TECHNOLOGY */}
      <Accordion.Item className="mobile-accordion" eventKey="1">
        <Accordion.Header>CUTTING-EDGE TECHNOLOGY</Accordion.Header>
        <Accordion.Body>
          Driven by cutting-edge technology, Ependisis.com offers a seamless and user-friendly online marketplace experience. Our innovative platform leverages advanced technology to enhance user interactions, streamline processes, and provide real-time insights.
        </Accordion.Body>
      </Accordion.Item>

      {/* TRANSPARENCY AND RELIABILITY */}
      <Accordion.Item className="mobile-accordion" eventKey="2">
        <Accordion.Header>TRANSPARENCY AND RELIABILITY</Accordion.Header>
        <Accordion.Body>
          Transparency and reliability are at the epicenter of Ependisis.com offers. We prioritize open communication and provide a secure, trustworthy environment for all our users. Whether you are a seller looking to position your company strategically or an investor seeking opportunities, you can rely on our platform for transparent information to achieve your goals.
        </Accordion.Body>
      </Accordion.Item>

      {/* SUPPORTING GROWTH AND SUCCESS */}
      <Accordion.Item className="mobile-accordion" eventKey="3">
        <Accordion.Header>SUPPORTING GROWTH AND SUCCESS</Accordion.Header>
        <Accordion.Body>
          Ependisis.com is more than just a marketplace; it is your partner in growth. Our platform is designed to support the success of both buyers and sellers by offering valuable resources, expert guidance, and a network of opportunities. Join us in fostering growth and achieving your investment goals through strategic and informed decision-making.
        </Accordion.Body>
      </Accordion.Item>

      {/* NAVIGATING THE M&A LANDSCAPE */}
      <Accordion.Item className="mobile-accordion" eventKey="4">
        <Accordion.Header>NAVIGATING THE M&A LANDSCAPE</Accordion.Header>
        <Accordion.Body>
          Navigate the complexities of the M&A landscape with confidence through Ependisis.com. Our platform equips you with the tools and insights needed to make informed decisions, mitigate risks, and capitalize on opportunities. Stay ahead in the dynamic world of mergers and acquisitions with our comprehensive support and resources.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionContainer;
