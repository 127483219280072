import React from "react";
import { Col, Badge } from "react-bootstrap";

import "./StatisticCard.css";
import "../../styles/Fonts.css";
import { StatisticCardProps } from "../../interfaces/components/StatisticCard/StatisticCardProps";

const StatisticCard: React.FC<StatisticCardProps> = ({
  icon,
  iconColor,
  text,
  statistic,
  backgroundColor,
}) => {
  return (
    <div
      className="statistic-card"
      style={{
        backgroundColor: backgroundColor,
        borderRadius: '10px',
        padding: '20px',
        textAlign: 'center',
        marginBottom: '20px',
        width: '100%', // Full width on mobile
        height: 'auto', // Auto height on mobile
      }}
    >
      <div style={{ marginBottom: '10px' }}>
        <div
          className="statistic-card-badge"
          style={{
            borderRadius: '10px',
            padding: '10px',
            height: '40px',
            width: '40px',
            color: iconColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '10%',
            marginBottom: '15%',
          }}
        >
          {icon}
        </div>
      </div>
      <div className="statistic-card-text">
        <p style={{ marginBottom: '0' }}>{text}</p>
      </div>
      <div className="statistic-card-statistic">
        <p style={{ fontSize: '24px', fontWeight: 'bold', lineHeight: '25px' }}>{statistic}</p>
      </div>
    </div>
  );
};

export default StatisticCard;

