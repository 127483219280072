import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
  ToggleButton,
} from "react-bootstrap";
import "../styles/Marketplace.css";
import HeaderMenuMarketplace from "../components/HeaderMenuMarketplace/HeaderMenuMarketplace";
import ListingCard from "../components/ListingCard/ListingCard";
import { AiFillHome } from "react-icons/ai";
import FooterMenu from "../components/FooterMenu/FooterMenu";
import AuthorizeWrapper from "../components/Auth/AuthorizeWrapper";
import { ListingItemProduct } from "../interfaces/models/ListingItemProduct";
import { GetAllCategory, GetProductsMarketPlace } from "../apis/EpendisisApi";
import { UserInfo } from "../redux/UserInfoReducer";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { getUserInfo } from "../auth/AuthUtils";
import { Input } from "@mui/material";
import { MarketplaceFilters } from "../interfaces/pages/Marketplace";
import { CategorysData } from "../admin/models/Categories";
import { useNavigate } from "react-router-dom";
import { ArrowRight, Building, Bank, BarChart } from "react-bootstrap-icons";
import { ProductIcons } from "../static/enums/ProductIcons";
import secureLocalStorage from "react-secure-storage";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import HeaderMenuMarketplaceMobile from "../components/HeaderMenuMarketplace/HeaderMenuMarketplaceMobile";

const initialFilters: MarketplaceFilters = {
  favourite: false,
  priceRange: [0, 100000],
};

const Marketplace: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<MarketplaceFilters>(initialFilters);
  const [categories, setCategories] = useState<CategorysData[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [favouriteEnabled, setFavouriteEnabled] = useState(false);
  const [selectedSort, setSelectedSort] = useState("Date listed: Newest");
  const [currentPage, setCurrentPage] = useState(1);
  const [listingsData, setListingsData] = useState<ListingItemProduct[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const userId = userInfo?.id?.toString();
  const itemsPerPage = 6;
  const [inputMinValue, setInputMinValue] = useState<string>("0");
  const [inputMaxValue, setInputMaxValue] = useState<string>("10000");
  const [sliderMinValueOverall, setSliderMinValueOverall] = useState(0);
  const [sliderMaxValueOverall, setSliderMaxValueOverall] = useState(10000);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [token, setToken] = useState<string | number | boolean | object | null>(
    ""
  );

  const fetchUserInfo = async () => {
    setLoading(true);
    try {
      const user = getUserInfo();
      const token = secureLocalStorage.getItem("token");
      setToken(token);
      if (user) {
        setUserInfo(user);
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    } finally {
      setLoading(false);
    }
  };

  const getIconForProduct = (productIcon: string): JSX.Element => {
    switch (productIcon) {
      case ProductIcons.ArrowRight:
        return <ArrowRight size={30} color="#fff" />;
      case ProductIcons.Bank:
        return <Bank size={30} color="#fff" />;
      case ProductIcons.Building:
        return <Building size={30} color="#fff" />;
      case ProductIcons.BarChart:
        return <BarChart size={30} color="#fff" />;
      default:
        return <AiFillHome size={30} color="#fff" />;
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await GetProductsMarketPlace(
        userId || "",
        token?.toString() ?? ""
      );
      setListingsData(data);

      const maxPrice = data.reduce((max, listing) => {
        const priceFeature = listing.features.find(
          (feature) => feature.featureTitle === "Price"
        );

        if (priceFeature) {
          const amount = Number(priceFeature.featureAmount);
          if (!isNaN(amount)) {
            return Math.max(max, amount);
          }
        }

        return max;
      }, -Infinity);

      const minPrice = data.reduce((min, listing) => {
        const priceFeature = listing.features.find(
          (feature) => feature.featureTitle === "Price"
        );

        if (priceFeature) {
          const amount = Number(priceFeature.featureAmount);
          if (!isNaN(amount)) {
            return Math.min(min, amount);
          }
        }

        return min;
      }, Infinity);

      setFilters((prevFilters) => ({
        ...prevFilters,
        priceRange: [
          isFinite(minPrice) ? minPrice : 0,
          isFinite(maxPrice) ? maxPrice : 10000,
        ],
      }));
      setSliderMinValueOverall(isFinite(minPrice) ? minPrice : 0);
      setSliderMaxValueOverall(isFinite(maxPrice) ? maxPrice : 10000);
      setInputMinValue(isFinite(minPrice) ? minPrice.toString() : "0");
      setInputMaxValue(isFinite(maxPrice) ? maxPrice.toString() : "10000");

      //CATEGORIES
      const categoryData = await GetAllCategory();
      setCategories(categoryData);
      setSelectedCategories(categoryData.map((category) => category.id));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Marketplace";
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userId) {
      if (
        userInfo?.status === "PendingVerification" ||
        userInfo?.status === "PendingDetails" ||
        userInfo?.status === "PendingDocuments" ||
        userInfo?.status === "PendingApproval"
      ) {
        navigate("/onboarding");
        return;
      } else {
        fetchData();
      }
    }
  }, [userId]);

  const handleSortSelect = (item: any) => {
    setSelectedSort(item);
  };

  const handleFavoriteToggle = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      favourite: !prevFilters.favourite,
    }));
    setFavouriteEnabled(!favouriteEnabled);
    setCurrentPage(1);
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleCategoryToggle = (categoryId: number) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(categoryId)) {
        // If already selected, remove it
        return prevSelected.filter((id) => id !== categoryId);
      } else {
        // If not selected, add it
        return [...prevSelected, categoryId];
      }
    });
  };

  const handlePriceRange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        priceRange: [
          Math.min(newValue[0], filters.priceRange[1]),
          filters.priceRange[1],
        ],
      }));
      setInputMinValue(Math.min(newValue[0], filters.priceRange[1]).toString());
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        priceRange: [
          filters.priceRange[0],
          Math.max(newValue[1], filters.priceRange[0]),
        ],
      }));
      setInputMaxValue(Math.max(newValue[1], filters.priceRange[0]).toString());
    }
  };

  const handlePriceRangeLabelMin = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      var newValue = Number(inputMinValue);
      if (newValue < sliderMinValueOverall) {
        newValue = sliderMinValueOverall;
      } else {
        newValue = Math.min(sliderMaxValueOverall, newValue);
      }
      setFilters((prevFilters) => ({
        ...prevFilters,
        priceRange: [newValue, filters.priceRange[1]],
      }));
      setInputMinValue(newValue.toString());
    }
  };

  const handlePriceRangeLabelMax = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      var newValue = Number(inputMaxValue);
      if (newValue > sliderMaxValueOverall) {
        newValue = sliderMaxValueOverall;
      } else {
        newValue = Math.max(sliderMinValueOverall, newValue);
      }
      setFilters((prevFilters) => ({
        ...prevFilters,
        priceRange: [filters.priceRange[0], newValue],
      }));
      setInputMaxValue(newValue.toString());
    }
  };

  const handlePriceRangeLabelMinChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setInputMinValue(numericValue);
  };

  const handlePriceRangeLabelMaxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setInputMaxValue(numericValue);
  };

  const handlePriceRangeLabelMinBlur = () => {
    const newValue = Number(inputMinValue);
    setInputMinValue(Math.min(newValue, filters.priceRange[1]).toString());
  };

  const handlePriceRangeLabelMaxBlur = () => {
    const newValue = Number(inputMaxValue);
    setInputMaxValue(Math.max(newValue, filters.priceRange[0]).toString());
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const applyFilters = () => {
    const selectedCategoryNames: string[] = selectedCategories.map(
      (categoryId) => {
        const matchingCategory = categories.find(
          (category) => category.id === categoryId
        );
        return matchingCategory ? matchingCategory.name : "";
      }
    );

    const noPriceData = listingsData
      .filter((listing) =>
        listing.features.every((feature) => feature.featureTitle !== "Price")
      )
      .filter((listing) => selectedCategoryNames.includes(listing.categoryName))
      .filter((listing) => {
        if (filters.favourite) {
          return listing.isFavourite === "true"; // Only show favourites
        }
        return true; // Show all listings if favourite filter is disabled
      });

    const filteredListings = listingsData
      // Only return favourites when the "favourite" filter is enabled
      .filter((listing) => {
        if (filters.favourite) {
          return listing.isFavourite === "true"; // Only show favourites
        }
        return true; // Show all listings if favourite filter is disabled
      })
      .filter((listing) =>
        listing.features.some(
          (feature) =>
            feature.featureTitle === "Price" &&
            Number(feature.featureAmount) >= filters.priceRange[0] &&
            Number(feature.featureAmount) <= filters.priceRange[1]
        )
      )
      .filter((listing) => selectedCategoryNames.includes(listing.categoryName))
      .filter((listing) =>
        listing.title.toLowerCase().includes(searchTerm.toLowerCase())
      );

    var combinedList = [...filteredListings, ...noPriceData];
    if (selectedSort === "Date listed: Newest") {
      combinedList = combinedList.sort((a, b) => {
        const dateA = new Date(a.dateAdded).getTime();
        const dateB = new Date(b.dateAdded).getTime();
        return dateB - dateA;
      });
    } else if (selectedSort === "Date listed: Oldest") {
      combinedList = combinedList.sort((a, b) => {
        const dateA = new Date(a.dateAdded).getTime();
        const dateB = new Date(b.dateAdded).getTime();
        return dateA - dateB;
      });
    }

    return combinedList;
  };

  const currentListings = applyFilters().slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <AuthorizeWrapper>

      <Container
        fluid
        className="wrapper"
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Row
        className="d-none d-sm-flex justify-content-md-center pt-0-mobile pt-15"
          style={{
            backgroundColor: "#FFFFFF",
            paddingTop: "15px",
          }}
        >
          <HeaderMenuMarketplace />
        </Row>
        <Row
        className="d-block d-sm-none justify-content-md-center pt-0-mobile pt-15"
          style={{
            backgroundColor: "#FFFFFF",
            paddingTop: "15px",
          }}
        >
          <HeaderMenuMarketplaceMobile />
        </Row>
        <hr style={{ marginTop: "0", opacity: "0.2" }} />
        <Row
          style={{
            paddingBottom: "17px"
          }}
          className="mt-45 mt-17-mobile justify-content-md-center"
        >
          <h3 className="heading-marketplace" style={{ paddingLeft: "10%" }}>
            All Listings
          </h3>
        </Row>
        <Row style={{ paddingLeft: "9%", paddingRight: "11%" }}>
          <Col xs="auto">
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-filter-1"
                className="mr-2 filter-marketplace"
                data-toggle="dropdown"
              >
                Type
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div
                  style={{
                    width: "auto",
                    paddingLeft: "12px",
                    maxWidth: "500px",
                    display: "flex",
                  }}
                >
                  {categories.map((category) => (
                    <Button
                      key={category.id}
                      variant={
                        selectedCategories.includes(category.id)
                          ? "success"
                          : "light"
                      }
                      style={{ marginRight: "12px" }}
                      className={
                        selectedCategories.includes(category.id)
                          ? "mr-2 filter-marketplace button-blue-theme"
                          : "mr-2 filter-marketplace"
                      }
                      onClick={() => handleCategoryToggle(category.id)}
                    >
                      {category.name}
                    </Button>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="auto">
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-filter-2"
                className="mr-2 filter-marketplace"
                data-toggle="dropdown"
              >
                Price
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  width: "250px",
                }}
              >
                <Row>
                  <Typography
                    className="mui-price-slider pl-15-mobile"
                    gutterBottom
                  >
                    Price Range
                  </Typography>
                </Row>
                <Row>
                  <Slider
                    value={filters.priceRange}
                    onChange={handlePriceRange}
                    valueLabelDisplay="auto"
                    aria-labelledby="price-slider"
                    disableSwap
                    min={sliderMinValueOverall}
                    max={sliderMaxValueOverall}
                    style={{
                      width: "80%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "flex",
                      color: "#536BC1",
                    }}
                  />
                </Row>
                <Row className="justify-content-between">
                  <Col xs={6}>
                    <Input
                      className="text-align-center"
                      onKeyDown={handlePriceRangeLabelMin}
                      onChange={handlePriceRangeLabelMinChange}
                      onBlur={handlePriceRangeLabelMinBlur}
                      style={{ marginLeft: "15%" }}
                      value={inputMinValue}
                      size="small"
                      inputProps={{
                        type: "number",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                  </Col>
                  <Col xs={6} style={{ display: "flex" }}>
                    <Input
                      className="text-align-center"
                      value={inputMaxValue}
                      onChange={handlePriceRangeLabelMaxChange}
                      onKeyDown={handlePriceRangeLabelMax}
                      onBlur={handlePriceRangeLabelMaxBlur}
                      size="small"
                      inputProps={{
                        type: "number",
                        "aria-labelledby": "input-slider",
                      }}
                      style={{
                        marginLeft: "auto",
                        marginRight: "15%",
                      }}
                    />
                  </Col>
                </Row>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="auto">
            <ToggleButton
              type="checkbox"
              variant="light"
              className={`mr-2 filter-marketplace ${
                favouriteEnabled ? "favourite" : ""
              }`}
              checked={favouriteEnabled}
              onChange={handleFavoriteToggle}
              id="favourites"
              value="favourite"
            >
              Favorites
            </ToggleButton>
          </Col>
          <Col
            xs="auto"
            style={{ marginLeft: "auto" }}
            className="w-100-mobile"
          >
            <Dropdown onSelect={handleSortSelect}>
              <Dropdown.Toggle
                style={{ minWidth: "173px", width: "auto" }}
                variant="light"
                id="another-dropdown"
                className="mr-2 filter-marketplace"
                data-toggle="dropdown"
              >
                {selectedSort ? selectedSort : "Sorting..."}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="Date listed: Newest">
                  Date listed: Newest
                </Dropdown.Item>
                <Dropdown.Item eventKey="Date listed: Oldest">
                  Date listed: Oldest
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="auto" className="w-100-mobile">
            <Form>
              <Form.Control
                type="text"
                placeholder="Search..."
                className="form-control-sm mr-2 filter-marketplace"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form>
          </Col>
        </Row>
        {!loading ? (
          <>
            <Row
              style={{
                paddingBottom: "30px",
                marginTop: "45px",
                paddingLeft: "9%",
                paddingRight: "11%",
                alignItems: "start",
                marginBottom: "25px",
                flex: "1",
              }}
              className={`mt-45 mt-17-mobile justify-content-${
                currentListings.length > 2 ? "between" : "center" 
              }`}
            >
              {currentListings.length > 0 ? (
                currentListings.map((listing, index) => (
                  <Col
                    key={index}
                    xs="auto"
                    className="listing-col"
                    style={{
                      marginLeft:
                        currentListings.length > 1 && index === 0 ? "auto" : "",
                      marginRight:
                        currentListings.length > 1 &&
                        index === currentListings.length - 1
                          ? "auto"
                          : "",
                    }}
                  >
                    <ListingCard
                      icon={getIconForProduct(listing.icon)}
                      title={listing.title}
                      isFavourite={listing.isFavourite === "true"}
                      text={listing.descriptionShort}
                      features={listing.features.slice(0, 3)}
                      redirectLink={`/marketplace/${listing.id}`}
                      active={listing.active}
                    />
                  </Col>
                ))
              ) : (
                <p style={{ textAlign: "center" }}>
                  No investments to display, please modify filter criteria!
                </p>
              )}
            </Row>
            <Row
              className="justify-content-md-center"
              style={{
                marginBottom: "25px",
                display: "flex", // Flexbox for better centering
                justifyContent: "center", // Centers the pagination
              }}
            >
              <ul
                className="pagination"
                style={{
                  display: "flex",
                  justifyContent: "center", // Ensures buttons are centered
                  padding: "0",
                  listStyle: "none",
                  flexWrap: "wrap", // Allows wrapping on smaller screens
                }}
              >
                {Array.from({
                  length: Math.ceil(currentListings.length / itemsPerPage),
                }).map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                    style={{
                      margin: "5px", // Adjust margin for better spacing
                    }}
                  >
                    <span
                      onClick={() => paginate(index + 1)}
                      className={`page-link ${
                        currentPage === index + 1 ? "active-link" : ""
                      }`}
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        display: "inline-block", // Keeps buttons inline
                      }}
                    >
                      {index + 1}
                    </span>
                  </li>
                ))}
              </ul>
            </Row>
          </>
        ) : (
          <Row
            className="justify-content-center"
            style={{ marginTop: "50px", marginBottom: "50px", flex: "1" }}
          >
            <Spinner animation="border" variant="primary" />
          </Row>
        )}

        <Row
          className="d-none d-sm-flex justify-content-center"
          id="footer-row"
          style={{
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingBottom: "2%",
            paddingTop: "2%",
          }}
        >
          <Footer />
        </Row>
        <Row
          className="d-block d-sm-none justify-content-center"
          style={{ marginLeft: "2%", marginRight: "2%", marginTop: "4%" }}
        >
          <FooterMobile />
        </Row>
      </Container>
    </AuthorizeWrapper>
  );
};

export default Marketplace;
