import { useEffect, useState } from "react";
import { getUserInfo, useLogout } from "../../auth/AuthUtils";
import { Nav, NavDropdown, Navbar, Container } from "react-bootstrap";
import logo from "../../assets/logo-black-letters.png";
import { PersonCircle } from "react-bootstrap-icons";
import { UserInfo } from "../../redux/UserInfoReducer";
import { Link } from "react-router-dom";
import "./HeaderMenuMarketplaceMobile.css";

function HeaderMenuMarketplaceMobile() {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const handleLogout = useLogout();

  const handleAdminPage = () => {
    console.log("Accessing Admin Page");
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = getUserInfo();
      if (user) {
        setUserInfo(user);
      }
    };
    fetchUserInfo();
  }, []);

  return (
    // <div className="overlay-container-marketplace">
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      className="mh-104 h-auto-mobile header-marketplace-navbar"
      style={{ width: "100%" }}
    >
      <Container
        fluid
        className="d-flex justify-content-between align-items-center"
      >
        {/* Navbar Toggle for Mobile */}
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          style={{ backgroundColor: "#333", borderColor: "#333" }}
        />

        {/* Logo - Center on mobile */}
        <Navbar.Brand
          href="/"
          className="mx-auto d-flex justify-content-center"
        >
          <img src={logo} alt="Logo" className="marketplace-header-logo" />
        </Navbar.Brand>

        {/* User Icon (conditionally rendered) */}
        <Nav className="ml-auto ai-center">
          {userInfo === null ? (
            <div className="join-now-btn responsive-link-container">
              <Nav.Link href="/login" id="join-link" className="btn">
                Log in
              </Nav.Link>
            </div>
          ) : (
            <NavDropdown
              title={
                <PersonCircle
                  size={30}
                  style={{ cursor: "pointer", color: "#222222" }}
                />
              }
              id="basic-nav-dropdown-marketplace"
              className="fixed-dropdown"
              align="end"
            >
              <NavDropdown.Item disabled>
                Hello, {userInfo.name}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              {userInfo?.roles === "Admin" ? (
                <NavDropdown.Item
                  as={Link}
                  to="/admin/dashboard"
                  onClick={handleAdminPage}
                >
                  Admin Page
                </NavDropdown.Item>
              ) : null}
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Container>

      {/* Collapsible menu for smaller screens */}
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto navbar-options-marketplace">
          <Nav.Link href="/marketplace">Listings</Nav.Link>
          <Nav.Link href="/home">Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>

    // </div>
  );
}

export default HeaderMenuMarketplaceMobile;
