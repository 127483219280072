// ListingCard.tsx
import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { BsHeart, BsHeartFill } from "react-icons/bs"; // Correct import paths
import ListingCardProps from "../../interfaces/components/ListingCard/ListingCardProps";
import "./ListingCard.css";

const ListingCard: React.FC<ListingCardProps> = ({
  icon,
  title,
  isFavourite,
  text,
  features,
  redirectLink,
  active,
}) => {
  return (
    <Card
      className="listing-card"
      style={{
        backgroundColor: active ? "#F8FAFF" : "rgb(255 208 208)",
        borderRadius: "15px",
        margin: "10px",
        borderColor: "#F8FAFF",
      }}
    >
      <Card.Body>
        {/* First Row */}
        <Row className="align-items-center">
          <Col xs="auto">
            <div
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "#536BC1",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {React.cloneElement(icon as React.ReactElement, {
                size: 25,
                color: "#fff",
              })}{" "}
            </div>
          </Col>

          <Col
            style={{ fontSize: "16px", fontWeight: "bold", textAlign: "left" }}
          >
            <a className="custom-link" href={redirectLink}>
              {title}
              {active ? "" : " - (NOT PUBLISHED)"}
            </a>
          </Col>
          <Col xs="auto">
            {isFavourite ? <BsHeartFill color="#FF4364" /> : <BsHeart />}
          </Col>
        </Row>

        {/* Second Row */}
        <Row style={{ marginTop: "10px", textAlign: "left", height: "58px" }}>
          <Col>
            <p
              className="listing-card-text"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {text}
            </p>
          </Col>
        </Row>

        {/* Third Row */}
        <Row style={{ marginTop: "10px" }}>
          {features
            .slice(0, Math.min(features.length, 3))
            .map((feature, index) => (
              <Col key={index}>
                <div
                  className="listing-card-tag-label"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {feature?.featureTitle}
                </div>
                <div className="listing-card-tag">{feature?.featureAmount}</div>
              </Col>
            ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ListingCard;
