import { Col, Row } from "react-bootstrap";
import "./FooterMobile.css";

const FooterMobile: React.FC = () => {
  return (
    <>
      <Row className="footer-row-mobile d-flex justify-content-center">
        <Col xs={6}>
          <Row
            className="link-footer-mobile"
            style={{ fontSize: "15px", color: "#2F3B68" }}
          >
            Useful Links
          </Row>
          <Row
            className="link-footer-mobile"
            style={{ fontSize: "15px", color: "#000000", marginTop: "10%" }}
          >
            <a href="/privacypolicy">Privacy Policy</a>
          </Row>
          <Row
            className="link-footer-mobile"
            style={{ fontSize: "15px", color: "#000000" }}
          >
            <a href="/cookiespolicy">Cookies Policy</a>
          </Row>
          <Row
            className="link-footer-mobile"
            style={{ fontSize: "15px", color: "#000000" }}
          >
            <a href="/termsandconditions">Terms and Condition</a>
          </Row>
        </Col>
        <Col xs={6} className="d-flex flex-column justify-content-start">
          <Row
            className="info-footer-mobile"
            style={{ fontSize: "15px", color: "#000000" }}
          >
            <a href="mailto:info@ependisis.com">info@ependisis.com</a>
          </Row>
          <Row
            className="info-footer-mobile"
            style={{ fontSize: "15px", color: "#000000", marginTop: "4%" }}
          >
            <a href="https://maps.app.goo.gl/HTgpzWAnNNJzLesU6">
              Gladstonos 31
            </a>
          </Row>
          <Row
            className="info-footer-mobile"
            style={{ fontSize: "15px", color: "#000000" }}
          >
            <a href="https://maps.app.goo.gl/HTgpzWAnNNJzLesU6">1095 Nicosia</a>
          </Row>
          <Row
            className="info-footer-mobile"
            style={{ fontSize: "15px", color: "#000000" }}
          >
            Cyprus
          </Row>
        </Col>
      </Row>
      <Row
        className="closing-footer"
        style={{
          fontSize: "12px",
          marginTop: "5%",
          opacity: 0.8,
          fontStyle: "italic",
        }}
      >
        <Col
          xs={12}
          className="mb-3 mb-sm-0"
          style={{ padding: 0, lineHeight: 1 }}
        >
          Ependisis.com is a technology and marketing company that facilitates a
          private marketplace. This platform is not open to retail investors and
          should not be construed as an invitation to invest. The content and
          services provided on this website are for informational purposes only.
          Ependisis.com does not offer investment advice, make recommendations,
          or hold client funds. All users are encouraged to seek professional
          advice before making any investment decisions.
        </Col>
        <Col
          xs={12}
          sm="auto"
          className="text-sm-left"
          style={{ padding: 0 }}
        >
          Ependisis.com © 2024
        </Col>
      </Row>
    </>
  );
};

export default FooterMobile;
