import { Col, Container, Row } from "react-bootstrap";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import PlanCardContainerNew from "../containers/PlanCardContainerNew";
import "../styles/Home.css";
import QuestionAnswerTool from "../components/QuestionAnswerTool/QuestionAnswerTool";
import QuestionAnswerToolProps from "../interfaces/components/QuestionAnswerTool/QuestionAnswerToolProps";
import QuestionAnswerSingle from "../interfaces/components/QuestionAnswerTool/QuestionAnswerSingle";
import PackageTableNew from "../components/PackageTableNew/PackageTableNew";
import ContactUs from "../components/ContactUs/ContactUs";
import Footer from "../components/Footer/Footer";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import PlanCardNewContainer from "../containers/PlanCardContainerNew";
import FooterMobile from "../components/Footer/FooterMobile";

const packages = [
  {
    name: "Basic",
    color: "#ffffff",
  },
  {
    name: "Premium",
    color: "#ffffff",
  },
  {
    name: "Enterprise",
    color: "#ffffff",
  },
];

const PlanCardData = [
  {
    title: "Basic",
    titleColor: "#FFFFFF",
    backgroundColor: "#636FA0",
    subtitle: "Browse the marketplace",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "Who is this plan for?",
        bullets: [
          "For aspiring investors who want to browse before starting their search",
        ],
      },
      {
        title: "What you get in this plan",
        bullets: [
          "Browse the marketplace to see what’s available",
          "View public listings of all startups including platinum and managed startups",
        ],
      },
    ],
  },
  {
    title: "Premium",
    titleColor: "#FFFFFF",
    backgroundColor: "#272F53",
    subtitle: "Reach out to founders and owners",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "Who is this plan for?",
        bullets: [
          "For career entrepreneurs ready to find and acquire their next venture",
        ],
      },
      {
        title: "What you get in this plan",
        bullets: [
          "Reach out to founders to discuss acquisition",
          "Unlimited access to vetted business of up to EUR 250,000 TTM revenue",
          "Up to 1 user",
        ],
      },
    ],
  },
  {
    title: "Enterprise",
    titleColor: "#FFFFFF",
    backgroundColor: "#636FA0",
    subtitle: "Acquire the best business available",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "Who is this plan for?",
        bullets: [
          "For serial acquirers who want  the biggest and best deals guided by experts.",
        ],
      },
      {
        title: "What you get in this plan",
        bullets: [
          "Obtain the best business available",
          "Unlimited access to vetted opportunities of all sizes, including those we manage",
          "Up to 5 users",
        ],
      },
    ],
  },
];

const questionAnswer: QuestionAnswerSingle[] = [
  {
    question:
      "Does an Enterprise subscription guarantee I’ll find a business I like?",
    answer:
      "Answer 1. Yes, absolutely. An Enterprisee subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
  {
    question: "Can I cancel a Premium or Enterprise subscription?",
    answer:
      "Answer 2. Yes, absolutely. An Enterprisee subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
  {
    question:
      "I already have a Premium subscription. Can I upgrade to Enterprise?",
    answer:
      "Answer 3. Yes, absolutely. An Enterprisee subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
  {
    question: "How does priority support work with an Enterprise subscription?",
    answer:
      "Answer 4. Yes, absolutely. An Enterprisee subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
  {
    question:
      "Do I need an Enterprise subscription to obtain more than one opportunity?",
    answer:
      "Answer 5. Yes, absolutely. An Enterprisee subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
];

function BuyersNew() {
  return (
    <Container fluid>
      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{ backgroundColor: "#101324" }}
      >
        <HeaderMenu />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
        style={{
          backgroundColor: "#101324",
        }}
      >
        <HeaderMenuMobile />
      </Row>
      <Row style={{ marginTop: "30px" }}>
        <h2 className="row-title h-center ">
          Manage and close your deals faster
        </h2>
      </Row>
      {/* Packages boxes      */}
      <Row
        style={{
          backgroundColor: "#FFFFFF",
          paddingBottom: "80px",
          marginTop: "30px",
        }}
        className="justify-content-md-center"
      >
        <PlanCardNewContainer data={PlanCardData} roleSelected="2" />
      </Row>
      {/*
      <Row
        style={{
          backgroundColor: "#FFFFFF",
          paddingBottom: "50px",
          marginTop: "20px",
        }}
        className="justify-content-md-center"
      >
        <h2 className="row-subtitle h-center ">
          Whether it’s your first acquisition or you invest for a living, we’ve
          got the right plan for you.
        </h2>
      </Row>
      <Row
        style={{
          backgroundColor: "#FFFFFF",
          paddingBottom: "110px",
          marginTop: "20px",
        }}
        className="justify-content-md-center"
      >
        <QuestionAnswerTool qas={questionAnswer} />
      </Row>
      */}
      {/* <Row
        style={{
          backgroundColor: "#FFFFFF",
          padding: "100px",
        }}
        className="justify-content-md-center"
      >
        <Col>
          <PackageTableNew />
        </Col>
      </Row> */}
      <Row className="justify-content-center" style={{ marginTop: "5%" }}>
        <ContactUs />
      </Row>
      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{ marginLeft: "1%", marginRight: "1%", marginTop: "1%" }}
      >
        <Footer />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
        style={{ marginLeft: "2%", marginRight: "2%", marginTop: "4%" }}
      >
        <FooterMobile />
      </Row>
    </Container>
  );
}

export default BuyersNew;
